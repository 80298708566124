import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService, LoaderService, CookieStorage, LocalDataStoreService, ToasterMessageService, LocalStorageService } from '../base/services';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../assets/i18n/locale';
import { lables } from '../../assets/i18n/error';
import { encryptPassword } from '../base/utils';
// import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
// import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public submitted: boolean;
  public events: any[] = [];
  public locale = 'en-US';
  public country_locale = new locale();
  errorMessage;
  isLoggedIn: boolean;
  authentication_error = false;
  public supportId = environment.SupportEmailId;
  public countries = [];
  public locales: any = null;
  loader = false;
  public error: lables = new lables();

  @ViewChild('loginButton', { static: false }) public loginButton: ElementRef;

  constructor(private _fb: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private toasterService: ToastrService,
    private toasterMessageService: ToasterMessageService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private cookieStorage: CookieStorage,
    private localDataStoreService: LocalDataStoreService,
    private localStorage: LocalStorageService,
    // private _oktaStateService: OktaAuthStateService, 
    // @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private oauthService: OAuthService
  ) {
    const locale_value = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (locale_value && this.country_locale.dictionary[locale_value]) {
      this.locale = this.country_locale.dictionary[locale_value];
      translate.setDefaultLang(this.locale);
    }
    this.loaderService.loaderState.subscribe((value) => {
      this.loader = value;
    })
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  async ngOnInit() {
    // console.log('ngOnInit: getCookie: ', this.cookieStorage.getCookie('token'));
    this.loaderService.show();
    // eslint-disable-next-line angular/timeout-service
    window.setTimeout(() => {
      // console.log('setTimeout: getCookie: ', this.cookieStorage.getCookie('token'));
      if(this.cookieStorage.getCookie('token') != null && this.cookieStorage.getCookie('token') != '') {
        location.reload();
      }
      this.loaderService.hide();
    }, 1500);

    // this.locale = this.cookieStorage.getCookie('locale') || 'en-US';
    // this.cookieStorage.setCookie('locale', this.locale);
    this.fetchCountries();
    //  this.fetchLanguages();
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });
    this.loginForm = this._fb.group({
      email: ['', [<any>Validators.required,
      <any>Validators.minLength(3),
      <any>Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]],
      password: ['', [<any>Validators.required, <any>Validators.minLength(8)]],
      locale: ['', [<any>Validators.required]]
    });
    this.loginForm.patchValue({ locale: 'en-US' });
    const localeFromStorage = this.cookieStorage.getCookie('locale');
    this.locale = localeFromStorage ? localeFromStorage : 'en-US';
    // fixes autofill on IE and Mozilla

    // setTimeout(async () => {
    //   // const userClaims = await this._oktaAuth.getUser();
    //   // console.log('okta getUser: ', userClaims);
    //   const accessToken = await this._oktaAuth.getAccessToken();
    //   console.log('okta accessToken: ', accessToken);
    // }, 5000);

    // const userClaims = await this._oktaAuth.getUser();
    // console.log('okta getUser: ', userClaims);


  }

  logout() {
    this.userService.logout();
  }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  // changeLocale() {
  //   this.cookieStorage.setCookie('locale', this.locale);
  //   this.locale = this.country_locale.dictionary[this.locale];
  //   this.switchLanguage(this.locale);
  // }

  onSubmit(user) {
    if (this.loginForm.invalid) {
      this.validateForm();
      return;
    }
    this.loaderService.show();
    this.loginButton.nativeElement.classList.add('disabled');
    this.isLoggedIn = true;
    user['password'] = encryptPassword(user['password'], user['email'].toLowerCase());
    this.userService.login(user).subscribe(res => {
      const isPassExpired = this.cookieStorage.getCookie('is_pass_expired') ?
        JSON.parse(this.cookieStorage.getCookie('is_pass_expired')) : false;
      isPassExpired ? this.router.navigate(['expired-password-reset']) : this.router.navigate(['dashboard']);

      this.isLoggedIn = false;
      this.loginButton.nativeElement.classList.remove('disabled');
      this.loaderService.hide();
    }, err => {
      this.loaderService.hide();
      this.loginButton.nativeElement.classList.remove('disabled');
      this.onError(err)
    });
  }

  public oktaLogin() {
    // console.log('okta Login');
    this.oauthService.initImplicitFlow();

  }

  // public async oktaLogin() : Promise<void> {
  //   await this._oktaAuth.signInWithRedirect().then(
  //     (x) => console.log('logged in success: ', x)
  //   );

  //   // this.oauthService.initImplicitFlow();

  //   const userClaims = await this._oktaAuth.getUser();
  //   console.log('okta getUser: ', userClaims);
  // }


  onError(err) {
    this.loaderService.hide();
    this.authentication_error = true;
    this.isLoggedIn = false;
    const errCode = this.getErrorCode(err);
    if (errCode) {
      const message = this.toasterMessageService.getErrorMessage(errCode);
      message ? this.toasterService.error(message) : null;
    }
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  get password(): UntypedFormControl {
    return this.loginForm.get('password') as UntypedFormControl;
  }

  get email(): UntypedFormControl {
    return this.loginForm.get('email') as UntypedFormControl;
  }

  validateForm() {
    const emailFC = this.loginForm.get('email') as UntypedFormControl;
    const passFC = this.loginForm.get('password') as UntypedFormControl;
    // Due to autofill issues, login form validations done through here
    if (emailFC.errors) {
      if (emailFC.errors.pattern) {
        this.showError(1103);
        return false;
      }
      if (emailFC.errors.required) {
        this.showError(1104);
        return false;
      }
    }
    if (passFC.errors) {
      if (passFC.errors.required) {
        this.showError(1105);
        return false;
      }
      if (passFC.errors.minlength) {
        this.showError(1106);
        return false;
      }
    }
  }

  showError(errorCode) {
    const message = this.toasterMessageService.getErrorMessage(errorCode);
    message ? this.toasterService.error(message) : null;
  }

  fetchCountries() {
    this.userService.getCommonData('countries').subscribe((countries) => {
      const countryData = countries['data'];
      if (countries && countryData) {
        this.localDataStoreService.updateCountries(countryData);
        this.countries = countryData;
      }
    });
  }

  fetchLanguages() {
    this.userService.getCommonData('languages').subscribe((languages) => {
      const languageData = languages['data'];
      if (languages && languageData) {
        this.localDataStoreService.updateLocales(languageData);
        this.locales = languageData;
      }
    });
    this.localDataStoreService.updateLocales(this.locales);
  }
}
