import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'box-modal',
  templateUrl: './box-modal.component.html',
  styleUrls: ['./box-modal.component.scss']
})
export class BoxModalComponent {
  // event emitter to destroy self
  @Output() closeSelf: EventEmitter<any> = new EventEmitter();
  @Input() isLoading = false;

  constructor() {
    this.isLoading = false;
  }

  collapse() {
    this.closeSelf.emit(null);
  }

}
