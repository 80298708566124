import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));

// declare var fetch;
// declare var localStorage;

// /**
//  * Dictionary to map the right file for locales
//  */
// const dictionary: any = {
//   // English
//   'en-AU': 'en-US', // English - Australia
//   'en-BZ': 'en-US', // English - Belize
//   'en-CA': 'en-US', // English - Canada
//   'en-CB': 'en-US', // English - Caribbean
//   'en-US': 'en-US', // English - United States
//   'en-GB': 'en-US', // English - Great Britain
//   'en-IN': 'en-US', // English - India
//   'en-IE': 'en-US', // English - Ireland
//   'en-JM': 'en-US', // English - Jamaica
//   'en-NZ': 'en-US', // English - New Zealand
//   'en-PH': 'en-US', // English - Phillippines
//   'en-ZA': 'en-US', // English - Southern Africa
//   'en-TT': 'en-US', // English - Trinidad

//   // DUTCH
//   // 'nl-NL': 'nl-NL', // Dutch - Netherlands
//   // 'nl-BE': 'nl-NL', // Dutch - Belgium

//   // FRENCH
//   'fr-BE': 'fr-FR', // French - Belgium
//   'fr-FR': 'fr-FR', // French - France
//   'fr-LU': 'fr-FR', // French - Luxembourg
//   'fr-MA': 'fr-FR', // French - Morocco
//   'fr-CH': 'fr-FR', // French - Switzerland

//   // GERMAN
//   'de-AT': 'de-DE', // German - Austria
//   'de-DE': 'de-DE', // German - Germany
//   'de-LI': 'de-DE', // German - Liechtenstein
//   'de-LU': 'de-DE', // German - Luxembourg
//   'de-CH': 'de-DE', // German - Switzerland

//   // SPANISH
//   'es-AR': 'es-ES',
//   'es-CL': 'es-ES',
//   'es-MX': 'es-ES',
//   'es-ES': 'es-ES'

//   // POLISH
//   // 'pl-PL': 'pl-PL',

//   // CHINESE
//   // 'zh-CN': 'zh-ZH', // Chinese - China
//   // 'zh-HK': 'zh-HK', // Chinese - Hong Kong
//   // 'zh-MO': 'zh-HK', // Chinese - Macau
//   // 'zh-SG': 'zh-ZH', // Chinese - Singapore
//   // 'zh-TW': 'zh-HK'  // Chinese - Taiwan
// };

// let effectiveLocale: string = 'en-US';

// (<any>window).getEffectiveLocale = () => {
//   return effectiveLocale;
// };

// function loadLocale(): Promise<any> {
//   const defaultLocale = 'en-US';
//   let locale;

//   try {
//     const userData = localStorage.getItem('user_data');

//     if (userData !== null) {
//       const userLang = JSON.parse(userData);
//       locale = userLang['locale'];
//     } else {
//       locale = localStorage.getItem('locale') || defaultLocale;
//     }

//     locale = locale.replace(new RegExp('"', 'g'), '');
//   } catch (ex) {
//     locale = defaultLocale;
//   }

//   return new Promise((resolve, reject) => {
//     effectiveLocale = dictionary[locale] || defaultLocale;
//     if (dictionary[locale] === defaultLocale) { return resolve(null); }
//     fetch(`assets/locale/messages.${dictionary[locale]}.xlf`).then((res: any) => {
//       if (res.status === 200) {
//         return res.text().then(resolve).catch(() => resolve(null));
//       }
//       return resolve(null);
//     }).catch((ex) => {
//       resolve(null);
//     });
//   });
// }

// loadLocale().then(boot).catch(boot);

// function boot(translationSource) {
//   platformBrowserDynamic().bootstrapModule(AppModule, {
//     providers: [
//       { provide: TRANSLATIONS, useValue: translationSource },
//       { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
//     ]
//   });
// }
