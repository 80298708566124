/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'app/base/services';
import { UserService, CookieStorage, LocalDataStoreService, ToasterMessageService } from '../../services';
import { ToastrService } from 'ngx-toastr';

import { CookieKey } from '../../../config/config';
import { isPowerAdmin, isRootAdmin } from 'app/base/utils';

@Component({
  selector: 'app-update-email-consent',
  templateUrl: './update-email-consent.component.html',
  styleUrls: ['./update-email-consent.component.scss']
})
export class UpdateEmailConsentComponent implements OnInit {

  public showCookieConsentPopup = false;
  userData: Record<string, unknown> = null;
  public isDiverseyEmail = false;

  @Output() hideConsentPopup: EventEmitter<any> = new EventEmitter();
  @Output() proceedAndHideConsentPopup: EventEmitter<any> = new EventEmitter();

  constructor(
    private toaster: ToastrService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private cookieStorage: CookieStorage,
    private toasterMessageService: ToasterMessageService,
    private localDataStoreService: LocalDataStoreService
  ) {
    // console.log('cos: ', this.cookieStorage.getCookie(CookieKey.COOKIE_CONSENT));

    // if (this.cookieStorage.getCookie(CookieKey.UPDATE_EMAIL_CONSENT_POPUP) == null || this.cookieStorage.getCookie(CookieKey.UPDATE_EMAIL_CONSENT_POPUP) == '') {
    //   this.showCookieConsentPopup = true;
    // }
  }

  ngOnInit(): void {

    this.localDataStoreService.currentUserState.subscribe((res) => {
      const userData = res;
      if (userData) {
        this.userData = userData;
        this.isDiverseyEmail = (userData['email'].includes('@diversey.com')) ? true : false;
        const cookieKeyUpdateEmail = CookieKey.UPDATE_EMAIL_CONSENT_POPUP + '_' + this.userData['id'];
        if (this.cookieStorage.getCookie(cookieKeyUpdateEmail) === null || this.cookieStorage.getCookie(cookieKeyUpdateEmail) === '' || (this.cookieStorage.getCookie(cookieKeyUpdateEmail) === 'false')) {
          this.showCookieConsentPopup = (this.isDiverseyEmail && !this.checkDiverseyRootOrPowerAdmin()) ? true : false;
        } else if(this.cookieStorage.getCookie(cookieKeyUpdateEmail) === 'true') {
          this.showCookieConsentPopup = false;
        }

      }
    });
  }


  checkDiverseyRootOrPowerAdmin() {
    const { id, accessible_nodes, org_id, user_role_id } = this.userData;
    return (isPowerAdmin(this.userData.user_role_id) || isRootAdmin(id, user_role_id, accessible_nodes, org_id));
  }

  public proceedClick() {
    this.updateCookieValue();
    this.proceedAndHideConsentPopup.emit(true);
  }

  public remindLaterClick() {
    this.updateCookieValue();
    this.hideConsentPopup.emit(true);
  }

  public updateCookieValue() {
    const cookieKeyUpdateEmail = CookieKey.UPDATE_EMAIL_CONSENT_POPUP + '_' + this.userData['id'];
    this.cookieStorage.setCookie(cookieKeyUpdateEmail, true);
  }

}
