import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-mini-ts';
import { locale } from '../../../../assets/i18n/locale';
import { CookieStorage } from '../../services/cookie-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  public year;
  public country_locale = new locale();
  public termsCondLink = environment.TERMS_CONDITIONS;
  constructor(private cookieStorage: CookieStorage, private translate: TranslateService) {
    let locales = this.cookieStorage.getCookie('locale') || 'en-US';
    if (locales && this.country_locale.dictionary[locales]) {
      locales = this.country_locale.dictionary[locales];
      this.translate.use(locales);
    }
  }

  ngOnInit() {
    this.year = moment.utc().year();
  }

}
