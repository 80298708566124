import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GlobalRefService {
  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  // get window object
  _window(): any {
    return this.window;
  }

  // get document object
  _document(): any {
    return this.document;
  }

  // wrapper for window object
  get nativeWindow(): any {
    return this._window();
  }

  // wrapper for document object
  get nativeDocument(): any {
    return this._document();
  }
}