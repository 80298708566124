import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest, HttpHandler,
  HttpEvent, HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { LocalStorageService } from 'app/base/services/localStorage.service';
import { Observable } from 'rxjs';
import { UserService } from 'app/base/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalDataStoreService } from './local-data-store.service';
import { CookieStorage } from './cookie-storage.service';
import { locale } from '../../../assets/i18n/locale';
import { lables } from '../../../assets/i18n/error';
import { ToasterMessageService } from '../../base/services/toaster.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  public country_locale = new locale();
  public error: lables = new lables();
  public locales = this._cookieStorage.checkCookieAvailability('locale') ? this._cookieStorage.getCookie('locale') : 'en-US';
  window: Window & typeof globalThis;
  constructor(
    private localStorageService: LocalStorageService,
    private _toaster: ToastrService,
    private _userService: UserService,
    private _router: Router,
    private _localDataStoreService: LocalDataStoreService,
    private toasterMessageService: ToasterMessageService,
    private _cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document) {
      this.window = this.document.defaultView;
  }
  /* every api must send token in http header */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._cookieStorage.getCookie('token') || null;
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // TBD //
      }
    }, (err: any) => {
      const errTypes = ['UnauthorizedSessionError', 'UnauthorizedAccessError'];
      const apiErrCodes = [500, 502, 503];
      if (err.status === 403 && err.error && errTypes.indexOf(err.error.message) > -1) {
        // redirect to the login route
        if (err.url.includes('logout')) {
          // if logout itself fails
          const cookieConsent = this.localStorageService.get('cookies_consent_accepted');
          localStorage.clear();
          this._userService.userNameSubject.next(null);
          if (cookieConsent) {
            this.localStorageService.set('cookies_consent_accepted', cookieConsent);
          }
          this._router.navigate(['']);
        } else {
          const message = this.toasterMessageService.getErrorMessage(1101);
          message ? this._toaster.error(message) : null;
          this._localDataStoreService.updateData(null);
          this._cookieStorage.clearCookie('token');
          this.window.setTimeout(() => {
            this.window.location.href = `${environment['DASHBOARD_HOST_URL']}#/login`;
          }, 2000);
        }
      }
      if (apiErrCodes.indexOf(err.status) > -1) {
        const message = this.toasterMessageService.getErrorMessage(1102);
        if (message) {
          this._toaster.error(message);
        }
      }
    }));
  }
}
