import { Directive, HostListener, ElementRef } from '@angular/core';
@Directive({
  selector: '[trimWhiteSpace]'
})
export class TrimWhiteSpaceDirective {

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('change') onchange() {
    this.el.nativeElement.value = this.trimWhiteSpace(this.el.nativeElement.value);
  }
  trimWhiteSpace(inputString) {
    return inputString.replace(/\s\s+/g, ' ').trim();
  }
}
