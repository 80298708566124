import { Component, Inject, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { UserService, LoaderService, CookieStorage } from 'app/base/services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../assets/i18n/locale';
import { encryptPassword, isDiverseyAdmin, isPowerAdmin  } from '../base/utils';
import { CommonService } from 'app/shared/common.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-update-email-token',
  templateUrl: './update-email-token.component.html',
  styleUrls: ['../login/login.component.scss', './update-email-token.component.scss']
})
export class UpdateEmailTokenComponent implements OnInit {
  public newPassForm: UntypedFormGroup;
  // public currentEmailForm: FormGroup;
  public currentEmailForm: UntypedFormGroup;
  public updateEmailForm: UntypedFormGroup;
  public isSubmitted = false;
  public isValidateEmailSubmitted = false;
  public token: string = null;
  loader = false;

  public country_locale = new locale();
  validRegToken: boolean;
  private userEmail: string;

  public userData = null;
  public passwordSuggestValue = '';
  public isDiverseyAdmin = false;
  public isCurrentEmailVerified = false;
  public newEmail = '';
  public isLoggedIn = false;

  window: Window;

  constructor(private _fB: UntypedFormBuilder,
    private fB: FormBuilder,
    private _router: Router,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private cookieStorage: CookieStorage,
    private commonService: CommonService,
    private clipboard: Clipboard,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document) {
      this.window = this.document.defaultView;
    let locales = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (locales && this.country_locale.dictionary[locales]) {
      locales = this.country_locale.dictionary[locales];
      translate.use(locales);
    }

    this._activatedRoute.params.subscribe((res: Params) => {
      this.token = res['updateEmailToken'] || null;
      //eslint-disable-next-line
      const patt = new RegExp('^[A-Fa-f0-9]{8}\-([A-Fa-f0-9]{4}\-?){3}\-[A-Fa-f0-9]{12}$', 'gm');
      if (!patt.test(this.token)) {
        this.token = null;
      }
    });

    if (this.cookieStorage.checkCookieAvailability('token')) {
      this.isLoggedIn = true;
    }
    
  }

  ngOnInit() {
    this.loaderService.show();
    if (this.token) {
      this._userService.validateUpdateEmailToken(this.token).subscribe((res) => {
        if (res) {
          // console.log('validateUpdateEmailToken: ', res);
          
          this.validRegToken = res.success;
          this.userEmail = res.data ? res.data.email : null;
          this.newEmail = res.data ? res.data.new_email : null; 
          if(res.data && res.data.is_diversey_admin !== undefined) {
            this.isDiverseyAdmin = res.data.is_diversey_admin;
          }
          // this.setPasswordMinLimit();
        }
        this.loaderService.hide();
      }, (err) => {
       
        this.validRegToken = false;
        this.loaderService.hide();
        // console.log('error: ',this.validRegToken, this.loader, err);
        
      });
    } else {
        this.validRegToken = false;
        this.loaderService.hide();
      // this._router.navigate(['dashboard']);
    }

    this.generateRandomPassword();
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });

    this.currentEmailForm =  this._fB.group({ 
      email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]] 
    });
   
    this.updateEmailForm = this._fB.group({ 
      email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]],
      new_email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      confirmPassword: ['', Validators.required]
    },{ validator: [this.passwordMatchValidator] });

  }

  setPasswordMinLimit() {
    if (this.updateEmailForm && this.updateEmailForm !== undefined && this.updateEmailForm.controls['password'] !== undefined) {
      if (this.isDiverseyAdmin) {
        if (this.updateEmailForm && this.updateEmailForm.controls['password']) {
          this.updateEmailForm.controls['password'].addValidators(Validators.minLength(15));
        }
      }
    }
  }

  applyClass(isValid: boolean): string {
    if (!isValid) {
      return 'submit-btn-disabled';
    } 
    return '';
    
  }

  currentEmalFormValid() {
    // console.log('currentEmalFormValid: ', this.currentEmailForm.valid);
    return this.currentEmailForm.valid;
  }

  updateEmalFormValid() {
    // console.log('updateEmalFormValid: ', this.updateEmailForm.valid);
    return this.updateEmailForm.valid;
  }

  validateCurrentEmail(emailFormData) {
    if(this.currentEmailForm.valid) {
      this.loaderService.show();
      this._userService.validateUpdateEmailTokenWithEmail(this.token, emailFormData).subscribe(res => {
        this.isValidateEmailSubmitted = true;
        this.toasterService.success(res.message);
        this.loaderService.hide();
        this.isCurrentEmailVerified = true;
        // console.log('validateUpdateEmailTokenWithEmail: ', res.data);
          this.userEmail = res.data ? res.data.email : null;
          this.newEmail = res.data ? res.data.new_email : null; 
          if(res.data && res.data.is_diversey_admin !== undefined) {
            this.isDiverseyAdmin = res.data.is_diversey_admin;
          }
          this.updateEmailForm.controls['email'].setValue(this.userEmail);
          this.updateEmailForm.controls['new_email'].setValue(this.newEmail);
          this.setPasswordMinLimit();
          this.generateRandomPassword();
      }, (err) => {
        this.isValidateEmailSubmitted = false;
        this.isCurrentEmailVerified = false;
        this.loaderService.hide();
        // if (this.getErrorMessage(err)) {
        //   this.toasterService.error(err.message);
        // }
      });
    }
    
  }

  logout() {
    this._userService.logout(false);
    this.window.setTimeout(() => {
      this.window.location.reload();
    }, 2000);
  }

  onSubmitUpdateEmail(formData) {
    // console.log('onSubmitUpdateEmail: ', formData);
    this.loaderService.show();
    const newPass = encryptPassword(formData.password, this.newEmail);
    const payload = {
      uuid: this.token,
      email: formData.email,
      new_email: formData.new_email,
      password: newPass
    }
    this._userService.updateEmailWithPassword(this.token, payload).subscribe(res => {
      this.isSubmitted = true;
      this.toasterService.success(res.message);
      this.toasterService.success('You can now use your new email to login');
      // this.loaderService.hide();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      // const that = this;
      this.window.setTimeout(() => {
        this.loaderService.hide();
        this._router.navigate(['/login']);
      }, 3000);
      // this._router.navigate(['/login']);
    }, (err) => {
      this.isSubmitted = false;
      this.loaderService.hide();
      if (this.getErrorMessage(err)) {
        this.toasterService.error(err.message);
      }
    });
  }

  getErrorMessage(err) {
    if (err && err.message) {
      const errorMessage = err.message;
      return errorMessage;
    }
  }


  get email() {
    return this.currentEmailForm.get('email') as UntypedFormControl;
  }

  get password() {
    return this.updateEmailForm.get('password') as UntypedFormControl;
  }

  get confirmPassword() {
    return this.updateEmailForm.get('confirmPassword') as UntypedFormControl;
  }

  passwordMatchValidator(AC: AbstractControl) {
    const pwd = AC.get('password').value;
    const confirmPwd = AC.get('confirmPassword').value;
    if (pwd && confirmPwd) {
      if (pwd !== confirmPwd) {
        AC.get('confirmPassword').setErrors({ MatchPassword: true });
      } else {
        return null;
      }
    }
  }

  generateRandomPassword() {
    const passwordtest = this.commonService.generateRandomPassword();
    this.passwordSuggestValue = passwordtest;
  }

  copyPasswordToClipboard() {
    this.clipboard.copy(this.passwordSuggestValue);
    this.toasterService.info('Password copied to the clipboard!');
  }


}
