import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { LocalDataStoreService } from './local-data-store.service';
import { CookieStorage } from './cookie-storage.service';
import { LocalStorageService } from './localStorage.service';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { ToasterMessageService } from './toaster.service';
import { LoaderService } from './loader.service';

@Injectable()
export class HttpClientService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  window: Window;
  constructor(
    private http: HttpClient,
    private localDataStoreService: LocalDataStoreService,
    private cookieStorage: CookieStorage,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private router: Router,
    private oauthService: OAuthService,
    private toaster: ToastrService,
    private toasterMessageService: ToasterMessageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  // GET: method with authorization header
  get(url) {
    return this.http.get(url, this.httpOptions)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }

  // GET: method without authorization header
  getDataWithNoAuth(url) {
    return this.http.get(url)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }

  // HttpClientModule Post method call
  post(url, postBody: any) {
    return this.http.post(url, postBody, this.httpOptions)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }

  delete(url, postBody: any) {
    return this.http.delete(url, this.httpOptions)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }
  put(url, putData) {
    return this.http.put(url, putData, this.httpOptions)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }

  putWithNoAuth(url, putData) {
    return this.http.put(url, putData)
      .pipe(map(res => res), catchError((error) => this.handleError(error)));
  }

  postWithNoAuth(url, postData) {
    return this.http.post(url, postData)
      .pipe(
        map(res => res),
        catchError((error) => this.handleError(error)));
  }

  upload(url: string, file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('files', file, file.name);
    }
    return this.post(url, formData);
  }
  // use this for setting next login session token
  updateHeaderToken(token: string) {
    this.deleteToken();
    if (token) {
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization', token);
    } else {
      throw new Error('Token not supplied!! Please provide proper JWT token');
    }
  }

  deleteToken() {
    this.httpOptions.headers = this.httpOptions.headers.delete('Authorization');
  }

  handleError(error: HttpErrorResponse) {
    // console.log('handleError: ', error);
    try {
      const errCode = this.getErrorCode(error);
      //  console.log('errCode: ', errCode, error.error.message);

      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        // console.log('message: ', message);

        // message ? this.toaster.error(message) : null;
        message ? this.toaster.error(message) : this.toaster.error(error.error.message);
      }
    } catch (err) {
      // console.log('err: ', error);
      if(error && error.error&& error.error.message) {
        this.toaster.error(error.error.message);
      }
    }
    
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else if (error.status == 403 && (error.error.code == 2114 || error.error.code == 1200)) {
      this.updateLocalStorage(true);
      // console.log('error: 403: ', error);
      // this.toaster.error(error.error.error);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.loaderService.show();
      // this.window.setTimeout(() => {
      //   // that.logoutOkta();
      // }, 2000);
      this.window.setTimeout(() => {
        that.clearStorage(true);
        this.loaderService.hide();
      }, 3000);
      
    } else if ((error.error.code == 2114 || error.error.code == 1200)) {
      this.toaster.error(error.error.error);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.loaderService.show();
      // this.window.setTimeout(() => {
      //   that.logoutOkta();
      // }, 2000);
      this.window.setTimeout(() => {
        this.clearStorage(true);
        this.loaderService.hide();
      }, 3000);
    }
    //  else if (error.status == 403 && (error.error.code !== 1120)) {
    //   this.updateLocalStorage(true);

    //   this.logoutOkta();
    //   this.window.setTimeout(() => {
    //     this.updateLocalStorage(true);
    //   }, 2000);
     
    // }
     else if (error.status == 400) {
      return throwError(error.error);
    }
    //  else if(error.status == 401) {
    //   console.log('error 401:  ', error);
    //   this.toaster.error(error.error.message);
    //   return throwError(error.error);
    // }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(error.error);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  updateLocalStorage(reload) {
    this.localDataStoreService.updateData(null);
    this.localDataStoreService.updateSelectedOrgDetails(null);
    this.cookieStorage.clearCookie('token');
    this.localDataStoreService.updateOrgChildrens(null);
    this.localDataStoreService.updateOrganizations(null);
    this.clearStorage(reload);
  }

  async logoutOkta() {
    this.updateLocalStorage(false);
    await this.oauthService.logOut();
  }

  clearStorage(reload) {
    this.deleteToken();
    // update next value in userNameSubject to null
    const cookieConsentValue = this.localStorageService.get('cookies_consent_accepted');
    const locale = this.cookieStorage.getCookie('locale');
    this.localStorageService.clearAll();
    // preserving value
    if (cookieConsentValue) {
      this.localStorageService.set('cookies_consent_accepted', cookieConsentValue);
    }
    // preserve user preferred language
    if (locale) {
      this.cookieStorage.setCookie('locale', locale);
    }
    if (reload) {
      // this.router.navigate(['']);
      // bug fix by moving to login page on logout
      this.router.navigate(['login']);

    }
    this.commonService.clientLogoSubscription.next(null);
  }

}

