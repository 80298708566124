/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'app/base/services';
// import { CookieStorage } from '../../services/cookie-storage.service';
import { UserService, CookieStorage, LocalDataStoreService, ToasterMessageService } from '../../services';
import { ToastrService } from 'ngx-toastr';

import { CookieKey, COOKIE_LIST, EXTERNAL_LINKS, COOKIE_LIST_DIVERSEY } from '../../../config/config';

@Component({
  selector: 'app-custom-cookie-consent',
  templateUrl: './app-custom-cookie-consent.component.html',
  styleUrls: ['./app-custom-cookie-consent.component.scss']
})
export class AppCustomCookieConsentComponent implements OnInit {

  public showCookieConsentOption: boolean = true;
  public showCustomizeConsentOption: boolean = false;
  public acceptAllCookie = false;
  public showCookieConsentPopup = false;
  userData: Record<string, unknown> = null;
  public cookieConsentList = COOKIE_LIST;
  public diverseyCookiePolicy = EXTERNAL_LINKS.DIVERSEY_COOKIE_POLICY;

  @Output() hideConsentPopup: EventEmitter<any> = new EventEmitter();

  constructor(
    private toaster: ToastrService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private cookieStorage: CookieStorage,
    private toasterMessageService: ToasterMessageService,
    private localDataStoreService: LocalDataStoreService
  ) {
    // console.log('cos: ', this.cookieStorage.getCookie(CookieKey.COOKIE_CONSENT));

    if (this.cookieStorage.getCookie(CookieKey.COOKIE_CONSENT) == null || this.cookieStorage.getCookie(CookieKey.COOKIE_CONSENT) == '') {
      this.showCookieConsentPopup = true;
    }
  }

  ngOnInit(): void {
    // if (this.localDataStoreService.userDetails) {
    //   this.userData = this.localDataStoreService.userDetails;

    // } else {
    //   this.userService.getUserDetails().subscribe((data) => {
    //     if (data) {
    //       this.userData = data;
    //     }

    //   });
    // }

    this.localDataStoreService.currentUserState.subscribe((res) => {
      const userData = res;
        if(userData) {
          this.userData = userData;
          if(userData && userData.email != undefined && userData.email.includes('@diversey.com')) {
            this.cookieConsentList.forEach(e => {
              if(e.value === 'Strictly Necessary') {
                e.cookieList = e.cookieList.concat(COOKIE_LIST_DIVERSEY);
              }
            });
          }
        }
    });
  }

  public onAcceptAll() {
    this.acceptAllCookie = true;
    // this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, 'Strictly Neccessary, Performace');
   
    const cookieSelected = [];
    this.cookieConsentList.forEach(e => {
      cookieSelected.push(e.value);
    });
    // console.log(cookieSelected, cookieSelected.join(','));
    // this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, cookieSelected.join(','));
    // this.showCookieConsentPopup = false;
    // this.showCookieConsentOption = false;
    // this.showCustomizeConsentOption = false;

    this.updateCookieConsent(cookieSelected.join(','));
  }

  public onCLickCustomizeSettings() {
    let previousCookieSelected = '';
    this.showCookieConsentOption = false;
    this.showCustomizeConsentOption = true;
    previousCookieSelected = '' + this.userData['cookie_consent'];
    if(previousCookieSelected != null && previousCookieSelected != '') {
      for (let i = 0; i < this.cookieConsentList.length; i++) {
        const element = this.cookieConsentList[i];
        if( !element.disabled && previousCookieSelected.includes(element.value)) {
          this.cookieConsentList[i].selected = true;
        }
      }
    }
    
  }

  public toggleSelect(index, event) {
    if (!this.cookieConsentList[index].disabled) {
      this.cookieConsentList[index].selected = !this.cookieConsentList[index].selected;
    }
    // console.log('toggleSelect', index, this.cookieConsentList[index]);
  }

  public expandCookieConsent(index) {
    this.cookieConsentList[index].expand = !this.cookieConsentList[index].expand;
  }

  public onAllowSelection() {
    const cookieSelected = [];
    // console.log('onAllowSelection', this.cookieConsentList);
    this.cookieConsentList.forEach(e => {
      if (e.selected) {
        cookieSelected.push(e.value);
      }
    });
    // console.log(cookieSelected, cookieSelected.join(','));
    // this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, cookieSelected.join(','));
    // this.showCookieConsentOption = false;
    // this.showCustomizeConsentOption = false;
    // this.showCookieConsentPopup = false;
    this.updateCookieConsent(cookieSelected.join(','));
  }

  public updateCookieConsent(consentValue) {
    this.showCookieConsentOption = false;
    this.showCustomizeConsentOption = false;
    this.showCookieConsentPopup = false;
    this.userService.updateCookieConsent(consentValue).subscribe((res) => {
      if (res && res.success) {
        
        this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, consentValue);
        
        this.updateUserLocalStore(consentValue);
        const message = res.message ? res.message : null;
        this.toaster.info(message);
        this.hideConsentPopup.emit(true);
      }
    }, (err) => {
      let tempConsentValue = consentValue;
      // console.log('updateCookieConsent err: ', err);
      if (consentValue.split(',').length > 1) {
        tempConsentValue = this.cookieConsentList[0].value + '';
        this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, tempConsentValue);
        
        this.updateUserLocalStore(tempConsentValue);
        const message = 'Internal Server Error Occured';
        this.toaster.warning(message);
        const message1 = 'Cookie Consent set to Strictly Necessary only';
        this.toaster.info(message1);
      } else {
        this.cookieStorage.setCookie(CookieKey.COOKIE_CONSENT, tempConsentValue);
        
        const message = 'Cookie Consent Saved Successfully';
        this.toaster.info(message);
        this.updateUserLocalStore(tempConsentValue);
      }
      this.hideConsentPopup.emit(true);
     
    });
  }

  updateUserLocalStore(consentValue) {

    if (this.userData) {
      this.userData['cookie_consent'] = consentValue;
      this.localDataStoreService.updateData(this.userData);
    }
  }

}
