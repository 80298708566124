import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgPipesModule } from 'ngx-pipes';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { OktaLogoutComponent } from './okta-logout/okta-logout.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UpdateEmailComponent } from './update-email/update-email.component';
import { UpdateEmailTokenComponent } from './update-email-token/update-email-token.component';
import { DeactivateAccountComponent } from './deactivate-account/deactivate-account.component';
import {
  UserProfileComponent,
  AppFooterComponent, AppHeaderComponent,
  TermsConditionsAcceptanceComponent, CookieConsentComponent, AppCustomCookieConsentComponent, UpdateEmailConsentComponent
} from 'app/base/components';
import {
  LocalStorageService, CommonService,
  ResponsiveService, UserService,
  GlobalRefService, LoggedInGuard,
  HttpClientService, LoaderService,
  HttpTokenInterceptor, CookieStorage,
  LocalDataStoreService, LocalDataResolver,
  ToasterMessageService, StorageService
} from 'app/base/services';
import { AuthGuard } from 'app/auth/auth.guard';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
// import { OktaAuth } from '@okta/okta-auth-js';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
// import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

import config from './app.config'; 

// const oktaAuth = new OktaAuth({
//   issuer: 'https://dev-43261493.okta.com/oauth2/default',
//   clientId: '0oa5orq2cpWj1Scgh5d7',
//   redirectUri:'http://localhost:4200'
// });


// const oktaAuth = new OktaAuth({
//   issuer: 'https://diversey.oktapreview.com/oauth2/default',
//   clientId: '0oa1aflbwdejh2c7d0h8',
//   redirectUri:'http://localhost:4200'
// });

// type PathMatch = "full" | "prefix" | undefined;

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
    // pathMatch: 'full' as PathMatch
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuard]
  }, {
    path: 'forgot-password',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('app/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  }, {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    resolve: [LocalDataResolver]
  }, {
    path: 'register',
    loadChildren: () => import('app/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'new-user-registration',
    canActivate: [AuthGuard],
    resolve: [LocalDataResolver],
    loadChildren: () => import('app/new-diversey-user/new-diversey-user.module').then(m => m.NewDiverseyUserModule)
  }, {
    path: 'expired-password-reset',
    canActivate: [AuthGuard],
    resolve: [LocalDataResolver],
    loadChildren: () => import('app/expired-password-reset/expired-password-reset.module').then(m => m.ExpiredPasswordResetModule)
  },
  {
    path: 'update-email/:updateEmailToken',
    // canActivate: [LoggedInGuard],
    component: UpdateEmailTokenComponent,
  },
  {
    path: 'okta-logout',
    canActivate: [AuthGuard],
    component: OktaLogoutComponent,
  }, {
    path: '**',
    redirectTo: '/dashboard'
  }
];

export function declarations() {
  return [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    UpdateEmailComponent,
    UpdateEmailTokenComponent,
    DeactivateAccountComponent,
    UserProfileComponent,
    AppFooterComponent,
    AppHeaderComponent,
    TermsConditionsAcceptanceComponent,
    CookieConsentComponent,
    AppCustomCookieConsentComponent,
    UpdateEmailConsentComponent,
    OktaLogoutComponent
  ];
}

// returns a Object that can load Translations using Http and json
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function modules() {
  return [
    CommonModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    NgPipesModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PdfViewerModule,
    // OktaAuthModule,
    OAuthModule.forRoot(),
    // JwksValidationHandler.f
  ];
}

@NgModule({
  declarations: declarations(),
  imports: modules(),
  providers: [
    HttpClientService,
    {
      provide: 'CommonObjects',
      useValue: {}
    },
    ToastrService,
    LoggedInGuard,
    LocalStorageService,
    StorageService,
    CommonService,
    ResponsiveService,
    UserService,
    ToasterMessageService,
    GlobalRefService,
    AuthGuard,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    LocalDataStoreService,
    CookieStorage,
    LocalDataResolver,
    { provide: OAuthStorage, useClass: StorageService}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
