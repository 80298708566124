export class lables {
  '1009' = {
    'en-US': 'Terms and Conditions already accepted.',
    'nl-NL': 'Algemene voorwaarden al geaccepteerd.',
    'fr-FR': 'Les conditions générales ont déjà été acceptées.',
    'de-DE': 'Allgemeine Geschäftsbedingungen bereits akzeptiert.',
    'pl-PL': 'Warunki już zaakceptowane.',
    'zh-ZH': '条款和条件已被接受。',
    'it-IT': 'Termini e condizioni già accettati',
    'tr-TR': 'Şartlar ve Koşullar zaten kabul edildi.',
    'fi-FI': 'Ehdot ovat jo hyväksyttyjä.',
    'da-DK': 'Vilkår og betingelser allerede accepteret.',
    'ar-AR': 'الشروط والأحكام المقبولة بالفعل.',
    'pt-PT': 'Termos e Condições já aceitos.',
    'es-ES': 'Términos y condiciones aceptadas',
    'sv-SE': 'Villkoren redan accepterade.',
    'sv-FI': 'Villkor och villkor redan accepterade.',
    'hu-HU': 'Felhasználálsi feltételek elfogadva',
    'el-EL': 'Όροι και προϋποθέσεις έχουν ήδη γίνει αποδεκτές.',
    'no-NO': 'Vilkår og betingelser allerede akseptert.',
    'ru-RU': 'Сроки и условия уже приняты.',
    'cs-CZ': 'Smluvní podmínky již byly přijaty.',
    'sl-SL': 'Določbe in pogoji, ki so že sprejeti.',
    'sk-SK': 'Zmluvné podmienky už boli prijaté.',
    'ko-KO': '이용 약관.'
  };
  '1010' = {
    'en-US': 'Term and Condtions not found for this user.',
    'nl-NL': 'Term en Condities niet gevonden voor deze gebruiker.',
    'fr-FR': 'Les conditions générales n\'ont pas été trouvées pour cet utilisateur.',
    'de-DE': 'Begriffe und Bedingungen wurden für diesen Benutzer nicht gefunden.',
    'pl-PL': 'Nie znaleziono terminu i warunków dla tego użytkownika.',
    'zh-ZH': '未找到此用户的术语和条件。',
    'it-IT': 'Termini e condizioni non trovati per questo utente.',
    'tr-TR': 'Bu kullanıcı için terim ve Condtions bulunamadı.',
    'fi-FI': 'Termiä ja käyttöehtoja ei löydy tälle käyttäjälle.',
    'da-DK': 'Term og forudsætninger ikke fundet for denne bruger.',
    'ar-AR': 'لم يتم العثور على مصطلح وشرفات لهذا المستخدم.',
    'pt-PT': 'Termo e Condição não encontrados para este usuário.',
    'es-ES': 'Términos y condiciones no encontrados para este usuario',
    'sv-SE': 'Villkoren kunde inte hittas för denna användare.',
    'sv-FI': 'Term och villkor som inte hittades för den här användaren.',
    'hu-HU': 'A felhasználó számára nem található felhasználási feltételek.',
    'el-EL': 'Όρος και Condtions δεν βρέθηκαν για αυτόν το χρήστη.',
    'no-NO': 'Term og betingelser som ikke ble funnet for denne brukeren.',
    'ru-RU': 'Term и Condtions не найдены для этого пользователя.',
    'cs-CZ': 'Termín a podmínky nebyly pro tohoto uživatele nalezeny.',
    'sl-SL': 'Term in pogoji ni mogoče najti za tega uporabnika.',
    'sk-SK': 'Podmienky a podmienky pre tohto používateľa neboli nájdené.',
    'ko-KO': '이 사용자에 대한 용어 및 조건이 없습니다.'
  };
  '1011' = {
    'en-US': 'Term and Condtion document not found for this user.',
    'nl-NL': 'Term en Conditie document niet gevonden voor deze gebruiker.',
    'fr-FR': 'Le document comportant les conditions générales n\'a pas été trouvé pour cet utilisateur.',
    'de-DE': 'Laufzeit- und Bedingungsdokument für diesen Benutzer nicht gefunden.',
    'pl-PL': 'Nie znaleziono dokumentu warunków i condtion dla tego użytkownika.',
    'zh-ZH': '未找到此用户的术语和条件文档。',
    'it-IT': 'Termini e condizioni documento non trovati per questo utente.',
    'tr-TR': 'Bu kullanıcı için Dönem ve Condtion belgesi bulunamadı.',
    'fi-FI': 'Termi ja Condtion-asiakirjaa ei löydy tälle käyttäjälle.',
    'da-DK': 'Term- og Condtion-dokument findes ikke for denne bruger.',
    'ar-AR': 'لم يتم العثور على مستند المصطلح و Condtion لهذا المستخدم.',
    'pt-PT': 'Termo e Condtion document não encontrado para este usuário.',
    'es-ES': 'Documento de términos y condiciones no encontrado para este usuario',
    'sv-SE': 'Villkor dokument kunde inte hittas för denna användare.',
    'sv-FI': 'Term och Condtion-dokument hittades inte för den här användaren.',
    'hu-HU': 'Ehhez a felhasználóhoz nem található felhasználási feltétel.',
    'el-EL': 'Δεν βρέθηκε έγγραφο όρος και υπόσταση για αυτόν τον χρήστη.',
    'no-NO': 'Term og Condtion dokument ikke funnet for denne brukeren.',
    'ru-RU': 'Документ Term и Condtion не найден для этого пользователя.',
    'cs-CZ': 'Pro tento uživatel nebyl nalezen doklad o termínu a stavu.',
    'sl-SL': 'Za ta uporabnik ni mogoče najti dokumenta Term in Condtion.',
    'sk-SK': 'Dokument Podmienky nebol pre tohto používateľa nájdený .',
    'ko-KO': '이 사용자에 대한 용어 및 조건 문서가 없습니다.'
  };
  '1013' = {
    'en-US': 'Documet not found',
    'nl-NL': 'Document niet gevonden',
    'fr-FR': 'Le document n\'a pas été trouvé',
    'de-DE': 'Dokument nicht gefunden',
    'pl-PL': 'Dokument nie został znaleziony',
    'zh-ZH': '找不到文件',
    'it-IT': 'Documento non trovato',
    'tr-TR': 'Documet bulunamadı',
    'fi-FI': 'Asiakirjaa ei löydy',
    'da-DK': 'Dokument ikke fundet',
    'ar-AR': 'المستند غير موجود',
    'pt-PT': 'Documento não encontrado',
    'es-ES': 'Documento no encontrado',
    'sv-SE': 'Dokument hittades inte',
    'sv-FI': 'Dokument hittades inte',
    'hu-HU': 'nem található dokumentum',
    'el-EL': 'Το έγγραφο δεν βρέθηκε',
    'no-NO': 'Dokument ikke funnet',
    'ru-RU': 'Документ не найден',
    'cs-CZ': 'Dokument nebyl nalezen',
    'sl-SL': 'Dokument ni najden',
    'sk-SK': 'Dokument sa nenašiel',
    'ko-KO': '문서를 찾을 수 없음'
  };
  '1100' = {
    'en-US': 'Some error occoured, please try again later.',
    'nl-NL': 'Er is een fout opgetreden. Probeer het later opnieuw.',
    'fr-FR': 'Une erreur s\'est produite, veuillez réessayer plus tard.',
    'de-DE': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    'pl-PL': 'Wystąpił błąd, spróbuj ponownie później.',
    'zh-ZH': '发生了一些错误，请稍后再试。',
    'it-IT': 'Si è verificato un errore, riprova più tardi.',
    'tr-TR': 'Bazı hata occoured, daha sonra tekrar deneyin.',
    'fi-FI': 'Joitakin virheitä, yritä myöhemmin uudelleen.',
    'da-DK': 'Der opstod en eller anden fejl, prøv igen senere.',
    'ar-AR': 'حدث خطأ ما ، يرجى المحاولة مرة أخرى في وقت لاحق.',
    'pt-PT': 'Algum erro ocorreu, por favor, tente novamente mais tarde.',
    'es-ES': 'Algún error ha sucedido, por favor inténtelo de nuevo',
    'sv-SE': 'Ett fel uppstod, vänligen prova igen senare.',
    'sv-FI': 'Något fel inträffade, var vänlig försök igen senare.',
    'hu-HU': 'Hiba történt, próbálkozzon később.',
    'el-EL': 'Παρουσιάστηκε κάποιο σφάλμα, δοκιμάστε ξανά αργότερα.',
    'no-NO': 'Noen feil oppstod, prøv igjen senere.',
    'ru-RU': 'Возникла некоторая ошибка, повторите попытку позже.',
    'cs-CZ': 'Vyskytla se nějaká chyba, zkuste to prosím později.',
    'sl-SL': 'Pojavila se je nekaj napak, poskusite znova pozneje.',
    'sk-SK': 'Vyskytla sa chyba, skúste to znova neskôr.',
    'ko-KO': '오류가 발생했습니다. 잠시 후 다시 시도하십시오.'
  };
  '1101' = {
    'en-US': 'User Session has expired, Please login again!',
    'nl-NL': 'Gebruikerssessie is verlopen, log opnieuw in!',
    'fr-FR': 'La session utilisateur a expiré, veuillez vous reconnecter!',
    'de-DE': 'User Session ist abgelaufen, bitte loggen Sie sich erneut ein!',
    'pl-PL': 'Sesja użytkownika wygasła, zaloguj się ponownie!',
    'zh-ZH': '用户会话已过期，请重新登录！',
    'it-IT': 'La sessione utente è scaduta, effettua nuovamente il login!',
    'tr-TR': 'Kullanıcı Oturumu sona erdi, Lütfen tekrar giriş yapın!',
    'fi-FI': 'Käyttäjän istunto on vanhentunut, kirjaudu sisään uudelleen!',
    'da-DK': 'Brugersession er udløbet, venligst login igen!',
    'ar-AR': 'انتهت صلاحية جلسة المستخدم ، يرجى تسجيل الدخول مرة أخرى!',
    'pt-PT': 'Sessão de Usuário expirou, Por favor, faça o login novamente!',
    'es-ES': 'La sesión del usuario ha experidao, por favor regístrese de nuevo',
    'sv-SE': 'Använder sessionstid har gått ut. Vänligen logga in igen!',
    'sv-FI': 'Användarsessionen har löpt ut, Vänligen logga in igen!',
    'hu-HU': 'A felhasználó ideje lejárt, kérjük, jelentkezzen be újra!',
    'el-EL': 'Η συνεδρία χρήστη έχει λήξει, παρακαλώ συνδεθείτε ξανά!',
    'no-NO': 'Brukeresesjon er utløpt, vennligst logg inn igjen!',
    'ru-RU': 'Сессия пользователя истекла, пожалуйста, войдите снова!',
    'cs-CZ': 'Uživatelská relace vypršela, znovu se přihlašte!',
    'sl-SL': 'Uporabniško seje je poteklo, prosimo, prijavite se ponovno!',
    'sk-SK': 'Platnosť relácie vypršala, prosím, prihláste sa znova!',
    'ko-KO': '사용자 세션이 만료되었습니다. 다시 로그인하십시오!'
  };
  '1102' = {
    'en-US': 'Something went wrong! Please try again in sometime!',
    'nl-NL': 'Er is iets fout gegaan! Probeer het later nog een keer!',
    'fr-FR': 'Il y a eu une erreur. Veuillez réessayer plus tard.',
    'de-DE': 'Etwas ist schief gelaufen! Bitte versuchen Sie es später erneut!',
    'pl-PL': 'Coś poszło nie tak! Spróbuj ponownie za jakiś czas!',
    'zh-ZH': '有些不对劲！请稍后再试一次！',
    'it-IT': 'Qualcosa è andato storto! Per favore riprova tra qualche momento!',
    'tr-TR': 'Bir şeyler ters gitti! Lütfen bir ara tekrar deneyin!',
    'fi-FI': 'Jotain meni pieleen! Yritä uudelleen joskus!',
    'da-DK': 'Noget gik galt! Prøv igen engang!',
    'ar-AR': 'هناك خطأ ما! يرجى المحاولة مرة أخرى في وقت ما!',
    'pt-PT': 'Algo deu errado! Por favor, tente novamente em algum momento!',
    'es-ES': '¡Algo fue mal! ¡Por favor, inténtalo más tarde!',
    'sv-SE': 'Någonting gick fel! Vänligen prova igen senare!',
    'sv-FI': 'Något gick fel! Var god försök igen någon gång!',
    'hu-HU': 'Valami elromlott! Kérjük, próbálja meg újra valamikor!',
    'el-EL': 'Κάτι πήγε στραβά! Δοκιμάστε ξανά κάποια στιγμή!',
    'no-NO': 'Noe gikk galt! Vennligst prøv igjen på en gang!',
    'ru-RU': 'Что-то пошло не так! Повторите попытку в какой-то момент!',
    'cs-CZ': 'Něco se pokazilo! Zkuste to prosím znovu někdy!',
    'sl-SL': 'Nekaj ​​je šlo narobe! Poskusite znova v nekem trenutku!',
    'sk-SK': 'Niečo sa pokazilo! Skúste to znova!',
    'ko-KO': '문제가 발생했습니다. 언젠가 다시 시도하십시오!'
  };
  '1103' = {
    'en-US': 'Please enter valid Email.',
    'nl-NL': 'Voer een geldig emailadres in.',
    'fr-FR': 'Veuillez entrer une adresse courriel valide.',
    'de-DE': 'Bitte eine gültige E-Mail-Adresse eintragen.',
    'pl-PL': 'Proszę podać poprawny adres e-mail.',
    'zh-ZH': '请输入有效的电邮。',
    'it-IT': 'Inserire una email valida, grazie.',
    'tr-TR': 'Lütfen geçerli E-posta girin.',
    'fi-FI': 'Anna kelvollinen sähköposti.',
    'da-DK': 'Indtast venligst gyldig Email.',
    'ar-AR': 'الرجاء إدخال بريد إلكتروني صحيح.',
    'pt-PT': 'Por favor insira um email válido.',
    'es-ES': 'Por favor introduzca un correo electrónico válido',
    'sv-SE': 'Ange giltig e-postadress.',
    'sv-FI': 'Ange giltig e-postadress.',
    'hu-HU': 'Kérjük adjon meg érvényes e-mail-t',
    'el-EL': 'Πληκτρολογήστε έγκυρο μήνυμα ηλεκτρονικού ταχυδρομείου.',
    'no-NO': 'Vennligst skriv inn gyldig Email.',
    'ru-RU': 'Введите действительный адрес электронной почты.',
    'cs-CZ': 'Zadejte platný e-mail.',
    'sl-SL': 'Vnesite veljavno e-poštno sporočilo.',
    'sk-SK': 'Zadajte platný email.',
    'ko-KO': '올바른 이메일을 입력하십시오.'
  };
  '1104' = {
    'en-US': 'Email address is required.',
    'nl-NL': 'E-mailadres is verplicht.',
    'fr-FR': 'L\'adresse courriel est obligatoire.',
    'de-DE': 'E-Mailadresse wird benötigt.',
    'pl-PL': 'Adres e-mail jest wymagany.',
    'zh-ZH': '电子邮件地址是必填项',
    'it-IT': 'È richiesto l\'indirizzo email',
    'tr-TR': 'E-posta adresi gereklidir.',
    'fi-FI': 'Sähköpostiosoite on pakollinen.',
    'da-DK': 'Email adresse er påkrævet.',
    'ar-AR': 'عنوان البريد الإلكتروني مطلوب.',
    'pt-PT': 'É necessário um endereço de e-mail.',
    'es-ES': 'Se requiere una dirección de correo electrónico',
    'sv-SE': 'E-postadress krävs.',
    'sv-FI': 'E-postadress krävs.',
    'hu-HU': 'E-mail cím szükséges.',
    'el-EL': 'Απαιτείται διεύθυνση ηλεκτρονικού ταχυδρομείου.',
    'no-NO': 'E-postadresse er nødvendig.',
    'ru-RU': 'Требуется адрес электронной почты.',
    'cs-CZ': 'Je vyžadována e-mailová adresa.',
    'sl-SL': 'E-poštni naslov je obvezen.',
    'sk-SK': 'Vyžaduje sa emailová adresa.',
    'ko-KO': '이메일 주소가 필요합니다.'
  };
  '1105' = {
    'en-US': 'Password is required.',
    'nl-NL': 'Een wachtwoord is verplicht.',
    'fr-FR': 'Le mot de passe est obligatoire.',
    'de-DE': 'Passwort wird benötigt.',
    'pl-PL': 'Wymagane jest hasło.',
    'zh-ZH': '密码是必需的。',
    'it-IT': 'Password richiesta',
    'tr-TR': 'Parola gereklidir.',
    'fi-FI': 'Salasana tarvitaan.',
    'da-DK': 'Adgangskode er påkrævet.',
    'ar-AR': 'كلمة المرور مطلوبة.',
    'pt-PT': 'Senha requerida.',
    'es-ES': 'Contreseña requerida',
    'sv-SE': 'Lösenord krävs.',
    'sv-FI': 'Lösenord krävs.',
    'hu-HU': 'Jelszó szükséges',
    'el-EL': 'Απαιτείται κωδικός πρόσβασης.',
    'no-NO': 'Passord er påkrevd.',
    'ru-RU': 'Необходим пароль.',
    'cs-CZ': 'Heslo je vyžadováno.',
    'sl-SL': 'Zahtevano je geslo.',
    'sk-SK': 'Heslo je povinné.',
    'ko-KO': '암호는 필수 항목입니다.'
  };
  '1106' = {
    'en-US': 'Password must be atleast 8 characters',
    'nl-NL': 'Wachtwoord moet ten minste 8 tekens lang zijn',
    'fr-FR': 'Le mot de passe doit contenir au moins 8 caractères',
    'de-DE': 'Das Passwort muss aus mindestens 8 Zeichen bestehen',
    'pl-PL': 'Hasło musi zawierać co najmniej 8 znaków',
    'zh-ZH': '密码必须至少8个字符',
    'it-IT': 'La password deve essere di almeno 8 caratteri',
    'tr-TR': 'Parola en az 8 karakter olmalıdır',
    'fi-FI': 'Salasanan on oltava vähintään 8 merkkiä',
    'da-DK': 'Adgangskoden skal være mindst 8 tegn',
    'ar-AR': 'يجب أن تكون كلمة المرور 8 أحرف على الأقل',
    'pt-PT': 'A senha deve conter pelo menos 8 caracteres',
    'es-ES': 'La contraseña debe tener al menos 8 caracteres',
    'sv-SE': 'Lösenord måste vara minst 8 tecken',
    'sv-FI': 'Lösenordet måste bestå av minst 8 tecken',
    'hu-HU': 'A jelszónak legalább 8 karaktert kell tartalmaznia',
    'el-EL': 'Ο κωδικός πρόσβασης πρέπει να είναι atleast 8 χαρακτήρες',
    'no-NO': 'Passordet skal inneholde minst 8 tegn',
    'ru-RU': 'Пароль должен быть не менее 8 символов',
    'cs-CZ': 'Heslo musí mít nejméně 8 znaků',
    'sl-SL': 'Geslo mora biti najmanj 8 znakov',
    'sk-SK': 'Heslo musí byť minimálne 8 znakov',
    'ko-KO': '비밀번호는 최소한 8 문자 여야합니다.'
  };
  '1107' = {
    'en-US': 'Please fill all the required fields.',
    'nl-NL': 'Vul alle verplichte velden in.',
    'fr-FR': 'Veuillez remplir tous les champs obligatoires.',
    'de-DE': 'Bitte füllen Sie alle erforderlichen Felder aus.',
    'pl-PL': 'Proszę wypełnić wszystkie wymagane pola.',
    'zh-ZH': '请填写所有必填字段。',
    'it-IT': 'Per favore compilare i campi richiesti',
    'tr-TR': 'Lütfen gerekli tüm alanları doldurun.',
    'fi-FI': 'Täytä kaikki vaaditut kentät.',
    'da-DK': 'Udfyld venligst alle de obligatoriske felter.',
    'ar-AR': 'يرجى ملء جميع الحقول المطلوبة.',
    'pt-PT': 'Por favor, preencha todos os campos obrigatórios.',
    'es-ES': 'Por favor rellene todos los campos.',
    'sv-SE': 'Vänligen fyll i alla obligatoriska fält.',
    'sv-FI': 'Vänligen fyll i alla obligatoriska fält.',
    'hu-HU': 'Kérjük töltse ki az összes szükséges mezőt',
    'el-EL': 'Συμπληρώστε όλα τα απαιτούμενα πεδία.',
    'no-NO': 'Vennligst fyll ut alle obligatoriske felt.',
    'ru-RU': 'Пожалуйста, заполните все необходимые поля.',
    'cs-CZ': 'Vyplňte prosím všechna povinná pole.',
    'sl-SL': 'Prosimo, izpolnite vsa zahtevana polja.',
    'sk-SK': 'Vyplňte všetky povinné polia.',
    'ko-KO': '필수 입력란을 모두 작성하십시오.'
  };
  '1108' = {
    'en-US': 'Please accept the Terms of Service and Privacy Policy.',
    'nl-NL': 'Accepteer de Servicevoorwaarden en het Privacybeleid.',
    'fr-FR': 'Veuillez accepter les conditions d\'utilisation et la politique de confidentialité.',
    'de-DE': 'Bitte akzeptieren Sie die Nutzungsbedingungen und Datenschutzbestimmungen.',
    'pl-PL': 'Zaakceptuj Warunki usługi i Politykę prywatności.',
    'zh-ZH': '请接受服务条款和隐私政策。',
    'it-IT': 'Accetta i Termini di servizio e l\'Informativa sulla privacy.',
    'tr-TR': 'Lütfen Hizmet Şartları ve Gizlilik Politikası nı kabul edin.',
    'fi-FI': 'Hyväksy käyttöehdot ja tietosuojakäytäntö.',
    'da-DK': 'Vi accepterer vilkårene for brug og privatlivspolitik.',
    'ar-AR': 'يرجى قبول شروط الخدمة وسياسة الخصوصية.',
    'pt-PT': 'Por favor, aceite os Termos de Serviço e Política de Privacidade.',
    'es-ES': 'Por favor, acepte los términos del servicio y la política de provacidad',
    'sv-SE': 'Vänligen acceptera användarvillkoren och sekretesspolicyn.',
    'sv-FI': 'Vänligen acceptera användarvillkoren och sekretesspolicyen.',
    'hu-HU': 'Kérjük fogadaja el a szolgáltatási feltételeket és az adatvédelmi irányelveket.',
    'el-EL': 'Παρακαλούμε αποδεχτείτε τους Όρους Παροχής Υπηρεσιών και την Πολιτική Απορρήτου.',
    'no-NO': 'Vennligst godta vilkårene for bruk og personvern.',
    'ru-RU': 'Примите Условия использования и Политику конфиденциальности.',
    'cs-CZ': 'Přijměte prosím smluvní podmínky a zásady ochrany osobních údajů.',
    'sl-SL': 'Sprejmite pogoje storitve in politiko zasebnosti.',
    'sk-SK': 'Prijmite prosím Zmluvné podmienky a Zásady ochrany osobných údajov.',
    'ko-KO': '서비스 약관 및 개인 정보 취급 방침에 동의하십시오.'
  };
  '1110' = {
    'en-US': 'Incorrect email address provided',
    'nl-NL': 'Incorrect e-mailadres verstrekt',
    'fr-FR': 'L\'adresse courriel fournie est incorrect',
    'de-DE': 'Falsche E-Mail-Adresse angegeben',
    'pl-PL': 'Podano niepoprawny adres e-mail',
    'zh-ZH': '电子邮件地址不正确',
    'it-IT': 'L\'Indirizzo email fornito è  errato',
    'tr-TR': 'Yanlış e-posta adresi sağlanmıştır',
    'fi-FI': 'Virheellinen sähköpostiosoite',
    'da-DK': 'Forkert e-mail-adresse angivet',
    'ar-AR': 'تم تقديم عنوان بريد إلكتروني غير صحيح',
    'pt-PT': 'Endereço de email incorreto fornecido',
    'es-ES': 'Correo electrónico incorrecto',
    'sv-SE': 'Ogiltig mejladress',
    'sv-FI': 'Felaktig e-postadress som tillhandahålls',
    'hu-HU': 'Helytelen e-mail cím',
    'el-EL': 'Εσφαλμένη διεύθυνση ηλεκτρονικού ταχυδρομείου που παρέχεται',
    'no-NO': 'Feil e-postadresse oppgitt',
    'ru-RU': 'Неправильный адрес электронной почты',
    'cs-CZ': 'Nesprávná e-mailová adresa byla poskytnuta',
    'sl-SL': 'Navajen je napačen e-poštni naslov',
    'sk-SK': 'Zadaná nesprávna e-mailová adresa',
    'ko-KO': '잘못된 이메일 주소가 제공되었습니다.'
  };
  '1120' = {
    'en-US': 'Incorrect password provided',
    'nl-NL': 'Incorrect wachtwoord verstrekt',
    'fr-FR': 'Le mot de passe fourni est incorrect.',
    'de-DE': 'Falsches Passwort angegeben',
    'pl-PL': 'Dostarczono nieprawidłowe hasło',
    'zh-ZH': '提供的密码不正确',
    'it-IT': 'Password errata',
    'tr-TR': 'Yanlış parola sağlanmıştır',
    'fi-FI': 'Virheellinen salasana',
    'da-DK': 'Forkert adgangskode',
    'ar-AR': 'كلمة المرور غير صحيحة المقدمة',
    'pt-PT': 'Senha incorreta fornecida',
    'es-ES': 'Contraseña incorrecta',
    'sv-SE': 'Ogiltig lösenord.',
    'sv-FI': 'Felaktigt lösenord tillhandahållet',
    'hu-HU': 'Helytelen jelszó megadása',
    'el-EL': 'Δόθηκε εσφαλμένος κωδικός πρόσβασης',
    'no-NO': 'Feil passord oppgitt',
    'ru-RU': 'Неверный пароль',
    'cs-CZ': 'Bylo poskytnuto nesprávné heslo',
    'sl-SL': 'Neveljavno geslo',
    'sk-SK': 'Zadané nesprávne heslo',
    'ko-KO': '잘못된 비밀번호가 제공되었습니다.'
  };
  '2113' = {
    'en-US': 'Incorrect current password',
    'nl-NL': 'Onjuist huidig ​​wachtwoord',
    'fr-FR': 'Le mot de passe actuel est incorrect',
    'de-DE': 'Falsches aktuelles Passwort',
    'pl-PL': 'Nieprawidłowe aktualne hasło',
    'zh-ZH': '当前密码不正确',
    'it-IT': 'password corrente errata',
    'tr-TR': 'Yanlış geçerli parola',
    'fi-FI': 'Virheellinen nykyinen salasana',
    'da-DK': 'Forkert nuværende adgangskode',
    'ar-AR': 'كلمة المرور الحالية غير صحيحة',
    'pt-PT': 'Senha atual incorreta',
    'es-ES': 'Contraseña incorrecta',
    'sv-SE': 'Ogiltig lösenord',
    'sv-FI': 'Felaktigt nuvarande lösenord',
    'hu-HU': 'Helytelen jelszó',
    'el-EL': 'Εσφαλμένος τρέχων κωδικός πρόσβασης',
    'no-NO': 'Feil nåværende passord',
    'ru-RU': 'Неверный текущий пароль',
    'cs-CZ': 'Nesprávné aktuální heslo',
    'sl-SL': 'Pogrešano trenutno geslo',
    'sk-SK': 'Nesprávne aktuálne heslo',
    'ko-KO': '현재 암호가 올바르지 않습니다.'
  };
  '2114' = {
    'en-US': 'Your Account has been deactivated temporarily, please contact admin to reactivate your account',
    'nl-NL': 'Uw account is tijdelijk gedeactiveerd. Neem contact op met admin om uw account opnieuw te activeren',
    'fr-FR': 'Votre compte a été temporairement désactivé, veuillez contacter l\'administrateur pour réactiver votre compte.',
    'de-DE': 'Ihr Konto wurde vorübergehend deaktiviert. Wenden Sie sich an den Administrator, um Ihr Konto zu reaktivieren',
    'pl-PL': 'Twoje konto zostało dezaktywowane tymczasowo, skontaktuj się z administratorem, aby ponownie aktywować swoje konto',
    'zh-ZH': '您的帐户已暂时停用，请与管理员联系以重新启动您的帐户',
    'it-IT': 'Il tuo account è stato temporaneamente disattivato, contatta l\'amministratore per riattivare il tuo account',
    'tr-TR': 'Hesabınız geçici olarak devre dışı bırakıldı, hesabınızı yeniden etkinleştirmek için lütfen yöneticiyle iletişime geçin',
    'fi-FI': 'Jou rekening is tydelik gedeaktiveer, kontak asseblief admin om jou rekening te heraktiveer',
    'da-DK': 'Din konto er blevet deaktiveret midlertidigt. Kontakt venligst admin for at genaktivere din konto',
    'ar-AR': 'تم إلغاء تنشيط حسابك مؤقتًا ، يرجى الاتصال بالمشرف لإعادة تنشيط حسابك',
    'pt-PT': 'Sua conta foi desativada temporariamente, entre em contato com o administrador para reativar sua conta',
    'es-ES': 'Su cuenta ha sido desactivada temporalmente, por favor contacte son el administrador de la cuenta',
    'sv-SE': 'Ditt konto har avaktiverats tillfälligt, var god kontakta din administratör för att aktivera ditt konto igen.',
    'sv-FI': 'Ditt konto har avaktiverats tillfälligt, var god kontakta admin för att aktivera ditt konto igen',
    'hu-HU': 'Fiókját ideiglenesen kikapcsolták, kérjük, lépjen kapcsolatba az adminisztrátorral a fiók újraaktiválásához',
    'el-EL': 'Ο λογαριασμός σας έχει απενεργοποιηθεί προσωρινά, \
    επικοινωνήστε με το διαχειριστή για να ενεργοποιήσετε ξανά το λογαριασμό σας',
    'no-NO': 'Kontoen din har blitt deaktivert på det tidspunktet, vennligst kontakt admin for å aktivere kontoen din på nytt',
    'ru-RU': 'Ваша учетная запись была деактивирована в то время, пожалуйста, \
    свяжитесь с администратором, чтобы активировать свою учетную запись',
    'cs-CZ': 'Účet byl deaktivován v okamžiku, kontaktujte administrátora a znovu aktivujte svůj účet',
    'sl-SL': 'Vaš račun je bil takrat deaktiviran. Obrnite se na skrbnika, da znova aktivirate račun',
    'sk-SK': 'Váš účet bol dočasne deaktivovaný, kontaktujte správcu a znova aktivujte svoj účet.',
    'ko-KO': '계정이 일시 중지되었습니다. 관리자에게 문의하여 계정을 다시 활성화하십시오.'
  };
  '4110' = {
    'en-US': 'User you are trying to use either doesn\'t exist or maybe deleted.',
    'nl-NL': 'De gebruiker die u probeert te gebruiken, bestaat niet of is misschien verwijderd.',
    'fr-FR': 'L\'utilisateur que vous essayez d\'utiliser n\'existe pas ou a peut-être été supprimé.',
    'de-DE': 'Benutzer, den Sie verwenden möchten, sind entweder nicht vorhanden oder möglicherweise gelöscht.',
    'pl-PL': 'Użytkownik, którego próbujesz użyć, nie istnieje lub może zostać usunięty.',
    'zh-ZH': '您尝试使用的用户不存在或可能已删除。',
    'it-IT': 'L\'utente che stai tentando di utilizzare o non esiste o potrebbe essere stato eliminato.',
    'tr-TR': 'Kullanmaya çalıştığınız kullanıcı yok veya belki silinmiş olabilir.',
    'fi-FI': 'Käyttäjä, jota yrität käyttää, ei ole olemassa eikä ehkä poisteta.',
    'da-DK': 'Bruger du forsøger at bruge enten eksisterer ikke eller måske slettes.',
    'ar-AR': 'المستخدم الذي تحاول استخدامه إما غير موجود أو ربما تم حذفه.',
    'pt-PT': 'O usuário que você está tentando usar não existe ou talvez tenha sido excluído.',
    'es-ES': 'El usuario con el que está intentando acceder no existe o ha sido borrado',
    'sv-SE': 'Användaren du försöker använda existerar inte eller har blivit borttagen.',
    'sv-FI': 'Användaren du försöker använda antingen existerar inte eller kanske raderas.',
    'hu-HU': 'A felhasználó, akit használni szeretne, vagy nem létezik, vagy lehet, hogy törölték.',
    'el-EL': 'Ο χρήστης που προσπαθείτε να χρησιμοποιήσετε είτε δεν υπάρχει είτε ίσως διαγραφεί.',
    'no-NO': 'Bruker du prøver å bruke, finnes ikke eller kanskje slettet.',
    'ru-RU': 'Пользователь, которого вы пытаетесь использовать, не существует и не может быть удален.',
    'cs-CZ': 'Uživatel, který se pokoušíte použít, neexistuje nebo může být smazán.',
    'sl-SL': 'Uporabnik, ki ga poskušate uporabiti, ne obstaja ali je morda izbrisan.',
    'sk-SK': 'Užívateľ, ktorého sa pokúšate použiť, buď neexistuje alebo je odstránený.',
    'ko-KO': '사용하려는 사용자가 존재하지 않거나 삭제되었을 수 있습니다.'
  };
  '8005' = {
    'en-US': 'Something went wrong! Please try again in sometime!',
    'nl-NL': 'Er is iets fout gegaan! Probeer het later nog een keer!',
    'fr-FR': 'La langue a été changée avec succès.',
    'de-DE': 'Etwas ist schief gelaufen! Bitte versuchen Sie es später erneut!',
    'pl-PL': 'Coś poszło nie tak! Spróbuj ponownie za jakiś czas!',
    'zh-ZH': '有些不对劲！请稍后再试一次',
    'it-IT': 'Lingua cambiata correttamente',
    'tr-TR': 'Bir şeyler yanlış gitti! Lütfen bir ara tekrar deneyin!',
    'fi-FI': 'Jotain meni pieleen! Yritä uudelleen joskus!',
    'da-DK': 'Noget gik galt! Prøv igen engang!',
    'ar-AR': 'هناك خطأ ما! يرجى المحاولة مرة أخرى في وقت ما!',
    'pt-PT': 'Algo deu errado! Por favor, tente novamente em algum momento!',
    'es-ES': 'Idioma cambiado satisfactoriamente',
    'sv-SE': 'Språk har ändrats.',
    'sv-FI': 'Något gick fel! Var god försök igen någon gång!',
    'hu-HU': 'A nyelv sikeresen megváltozott.',
    'el-EL': 'Κάτι πήγε στραβά! Δοκιμάστε ξανά κάποτε!',
    'no-NO': 'Noe gikk galt!Vennligst prøv igjen på en gang!',
    'ru-RU': 'Что-то пошло не так!Повторите попытку в какой-то момент!',
    'cs-CZ': 'Něco se pokazilo!Zkuste to prosím znovu někdy!',
    'sl-SL': 'Nekaj je šlo narobe!Poskusite znova v nekem trenutku!',
    'sk-SK': 'Jazyk úspešne zmenený.',
    'ko-KO': '문제가 발생했습니다.언젠가 다시 시도하십시오!'
  };
}
