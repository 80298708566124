import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-email-token',
  templateUrl: './invalid-email-token.component.html',
  styleUrls: ['./invalid-email-token.component.scss']
})
export class InvalidEmailTokenComponent implements OnInit {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void { }

}
