import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppLoaderComponent, PopupComponent,
  BoxModalComponent, InvalidTokenTemplateComponent, LanguageDropdownComponent } from './components';
import { MockHierarchyDropdownServiceHandler, MockCustomHierarchyDropdownServiceHandler } from './tests/mock-classes';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrimWhiteSpaceDirective } from '../dashboard/directives/trimWhiteSpace';
import { FloatLabelDirective } from './directives/float-label.directive';
import { InvalidEmailTokenComponent } from './components/invalid-email-token/invalid-email-token.component';

//  returns a Object that can load Translations using Http and json
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppLoaderComponent,
    PopupComponent,
    BoxModalComponent,
    TrimWhiteSpaceDirective,
    InvalidTokenTemplateComponent,
    FloatLabelDirective,
    LanguageDropdownComponent,
    InvalidEmailTokenComponent
  ],
  exports: [
    AppLoaderComponent,
    PopupComponent,
    CommonModule,
    FormsModule,
    BoxModalComponent,
    TrimWhiteSpaceDirective,
    InvalidTokenTemplateComponent,
    InvalidEmailTokenComponent,
    FloatLabelDirective,
    LanguageDropdownComponent
  ],
  providers: [
    MockHierarchyDropdownServiceHandler,
    MockCustomHierarchyDropdownServiceHandler,
    CommonService
  ]
})

export class SharedModule { }
