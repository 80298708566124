import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocalDataStoreService {

  public currentUserState: BehaviorSubject<any> = new BehaviorSubject(null);
  public userDetails = null;
  public countriesSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public solutionsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public localesSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public organizations: BehaviorSubject<any> = new BehaviorSubject(null);
  public org_childrens: BehaviorSubject<any> = new BehaviorSubject(null);
  public filterOptions: BehaviorSubject<any> = new BehaviorSubject(null);
  public intelliDish: BehaviorSubject<any> = new BehaviorSubject(null);
  public selected_org_details: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentSelectedOrg: any = null;

  constructor() { /** */ }

  updateData(data: any) {
    this.userDetails = data;
    this.currentUserState.next(data);
  }

  updateCountries(data: any) {
    this.countriesSubject.next(data);
  }

  updateSolutions(data: any) {
    this.solutionsSubject.next(data);
  }

  updateLocales(data: any) {
    this.localesSubject.next(data);
  }

  updateOrganizations(data: any) {
    this.organizations.next(data);
  }

  updateOrgChildrens(data: any) {
    this.org_childrens.next(data);
  }

  updateFilterOptions(data: any) {
    this.filterOptions.next(data);
  }

  updateIntellidish(data: any) {
    this.intelliDish.next(data);
  }

  updateSelectedOrgDetails(data) {
    this.currentSelectedOrg = data;
    this.selected_org_details.next(data);
  }
}
