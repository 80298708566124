import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[floatLabel]'
})

export class FloatLabelDirective {
  @Input() floatLabel: UntypedFormControl;
  private nativeElement = this.el.nativeElement;
  private siblingLabel;
  constructor(private el: ElementRef) {
    this.siblingLabel = this.nativeElement['previousElementSibling'];
  }

  @HostListener('focus') onFocus() {
    if (this.siblingLabel && this.siblingLabel.nodeName === 'LABEL') {
      this.float();
    }
  }

  @HostListener('blur') onBlur() {
    if (this.siblingLabel && this.siblingLabel.nodeName === 'LABEL') {
      if (this.controlValue) {
        this.unFloat();
      }
    }
  }

  get controlValue () {
   // return this.floatLabel.hasOwnProperty('value') && !this.floatLabel.value;
    return Object.prototype.hasOwnProperty.call(this.floatLabel, 'value') && !this.floatLabel.value;
  }

  float() {
    this.siblingLabel.classList.add('float');
  }

  unFloat() {
    this.siblingLabel.classList.remove('float');
  }
}
