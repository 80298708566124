import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService, CookieStorage, LocalDataStoreService, ToasterMessageService } from 'app/base/services';
import { UserService } from 'app/base/services';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../../../assets/i18n/locale';
import { success } from '../../../../assets/i18n/success';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  // username, email and locale - set from local storage
  public newLocale: string;
  public currentLocale: string;
  public userData: any;
  public country_locale = new locale();
  public success: success = new success();
  // form group for user profile
  public userProfile: UntypedFormGroup;
  public locales;
  // for checking if user has hit submit
  formSubmitted = false;

  // for current focused form control
  focusedControl: UntypedFormControl = null;
  loader = false;
  languages = [];
  public disableSubmit = true;
  window: Window & typeof globalThis;
  constructor(
    private localDataStoreService: LocalDataStoreService,
    private userService: UserService,
    private _fb: UntypedFormBuilder,
    private toaster: ToastrService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private toasterMessageService: ToasterMessageService,
    private cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    this.currentLocale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (this.currentLocale) {
      const tempVal = this.country_locale.dictionary[this.currentLocale];
      if (tempVal) {
        this.locales = tempVal;
        this.translate.use(this.locales);
      }
    }
  }

  ngOnInit() {
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });
    this.loaderService.show();
    this.localDataStoreService.currentUserState.subscribe((res) => {
      this.loaderService.hide();
      this.userData = res || null;
      if (this.userData) {
        this.initUserProfileForm();
        this.newLocale = this.userData['locale'] || this.currentLocale;
        this.userProfile.patchValue({
          locale: this.newLocale,
        });
      }
    });
    this.userService.getCommonData('languages').subscribe(res => {
      if (res && res['data']) {
        this.languages = res['data'];
      }
    });
  }

  showError(message) {
    this.toaster.error(message);
  }

  initUserProfileForm() {
    /* initialize user profile form */
    this.userProfile = this._fb.group({
      email: [this.userData.email],
      locale: [this.userData.locale, [Validators.required]],
      username: [this.userData.username, [Validators.required, Validators.minLength(4)]]
    });
    this.loaderService.hide();
  }

  onSelect(language) {
    this.disableSubmit = (language.locale === this.userData.locale);
    this.userProfile.patchValue(language);
  }

  onSubmit() {
    this.loaderService.show();
    this.formSubmitted = true;
    const valuesChanged = {};
    Object.keys(this.userProfile.value).forEach(element => {
      const condition = this.userData[element] !== this.userProfile.value[element];
      if (condition) {
        valuesChanged[element] = this.userProfile.value[element];
      }
    });
    if (Object.keys(valuesChanged).length) {
      this.userService.updateProfile(valuesChanged)
        .subscribe(() => {
          this.loaderService.hide();
          const message = this.toasterMessageService.getSuccessMessage(8001);
          this.toaster.success(message);
          // Update localStorage
          Object.keys(valuesChanged).forEach(element => {
            this.userData[element] = valuesChanged[element];
          });
          this.userService.getLocaleDetails(this.userData)
            .subscribe(() => {
              this.localDataStoreService.updateData(this.userData);

              if (Object.prototype.hasOwnProperty.call(valuesChanged, 'locale')) {
                this.cookieStorage.setCookie('locale', this.userProfile.value['locale']);
                this.window.location.reload();
              }

              if (Object.prototype.hasOwnProperty.call(valuesChanged, 'username')) {
                this.userService.userNameSubject.next(valuesChanged['username']);
              }
              this.notify.emit();
            });
        }, (error) => {
          this.loaderService.hide();
          const errMsg = this.getErrorMessage(error);
          errMsg ? this.showError(errMsg) : null;
        });
    } else {
      this.loaderService.hide();
    }
  }

  getErrorMessage(error) {
    if (error && error.error) {
      if (error.error.message) {
        const errorMessage = error.error.message;
        return errorMessage;
      }
    }
  }

  onFocus(control) {
    this.focusedControl = control;
  }

  onBlur() {
    this.focusedControl = null;
  }

  get email(): UntypedFormControl {
    return this.userProfile.get('email') as UntypedFormControl;
  }

  get locale(): UntypedFormControl {
    return this.userProfile.get('locale') as UntypedFormControl;
  }

  get username(): UntypedFormControl {
    return this.userProfile.get('username') as UntypedFormControl;
  }
}
