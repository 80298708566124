import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/localStorage.service';
import { UserService } from '../../services/user.service';
import { CookieStorage } from '../../services/cookie-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterMessageService } from '../../../base/services';
import { locale } from '../../../../assets/i18n/locale';
import { lables } from '../../../../assets/i18n/error';
import { ToastrService } from 'ngx-toastr';
import { LocalDataStoreService } from '../../services/local-data-store.service';

const COOKIE_CONSENT_KEY = 'cookies_consent_accepted';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['../terms-conditions-acceptance/terms-conditions-acceptance.component.scss', './cookie-consent.component.scss'],
  host: {
    '(document:click)': 'close($event)'
  }
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  public isAccepted = true; // true hide, false show
  public termConditions = false;
  private userNameSubjectSubscription: Subscription;

  public country_locale = new locale();
  public error: lables = new lables();
  pdfSrc: any = null;
  pdfSrcId: number;
  public loading = false;
  isLoaded = false;
  public locales = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';

  constructor(
    private toasterService: ToastrService,
    private localStore: LocalStorageService,
    public router: Router,
    private toasterMessageService: ToasterMessageService,
    private userService: UserService, private eRef: ElementRef,
    private translate: TranslateService, private cookieStorage: CookieStorage,
    private localDataStoreService: LocalDataStoreService) {
    let locales = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (locales && this.country_locale.dictionary[locales]) {
      locales = this.country_locale.dictionary[locales];
      this.translate.use(locales);
    }
  }

  ngOnInit() {
    this.termConditions = false;
    // Not checking any cookies if user is Anonymous.
    this.isAccepted = this.cookieStorage.checkCookieAvailability('token') ? !!this.localStore.get(COOKIE_CONSENT_KEY) : true;
    this.userNameSubjectSubscription = this.userService.userNameSubject.subscribe((userName: string) => {
      this.isAccepted = userName ? !!this.localStore.get(COOKIE_CONSENT_KEY) : true;
    });

    this.localDataStoreService.currentUserState.subscribe((res) => {
      const userData = res;
      if (userData) {
        const termsId = userData.term_condition_id || null;
        if (termsId) {
          this.fetchTermsAncCondition(termsId);
        }
      }
    });
  }
  ngOnDestroy() {
    if (this.userNameSubjectSubscription) {
      this.userNameSubjectSubscription.unsubscribe();
    }
  }
  onAccepted() {
    this.isAccepted = true;
    this.localStore.set(COOKIE_CONSENT_KEY, 'true');
  }
  openTermCondition($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.termConditions = true;
  }
  close(event) {
    if (this.termConditions) {
      this.termConditions = false;
    }
  }

  fetchTermsAncCondition(id) {
    this.userService.getTerms(id).subscribe((res) => {
      const { success,  data } = res;
      if (res && success && data) {
        this.pdfSrc = data['current_version'] || null;
      }
    }, (err) => {
      this.pdfSrc = null;
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toasterService.error(message) : null;
      }
    });
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  showBoxModal(event) {
    event.stopPropagation();
    this.termConditions = !this.termConditions;
  }

  onProgress(event) {
    this.isLoaded = false;
  }

  afterLoadComplete(pdfData: any) {
    this.isLoaded = true;
  }

  onError(error: any) {
    const message = this.toasterMessageService.getErrorMessage(1100);
    message ? this.toasterService.error(message) : null;
    this.isLoaded = true;
  }

}
