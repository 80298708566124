import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService, CookieStorage, LocalDataStoreService, ToasterMessageService, UserService } from 'app/base/services';
import { CookieKey } from 'app/config/config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-okta-logout',
  templateUrl: './okta-logout.component.html',
  styleUrls: ['./okta-logout.component.scss']
})
export class OktaLogoutComponent implements OnInit {
  window: Window;
  public isLoading = false;
  
  constructor(private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private localDataStoreService: LocalDataStoreService,
    private toasterMessageService: ToasterMessageService,
    private cookieStorage: CookieStorage,
    @Inject(DOCUMENT) private document: Document) {
      this.window = this.document.defaultView;
     }

  ngOnInit(): void {
    this.userService.getUserDetails().subscribe((user)=> {
      // console.log('logout function', user.is_okta_login, user.email);
        if(user && user.is_okta_login && user.email != undefined && user.email.includes('@diversey.com')) {
          // console.log('logout okta', user);
          this.userService.logoutOkta(user.email);
        } else {
          // console.log('logout', user);
          this.userService.logout(true,user.email);
          this.cookieStorage.clearCookie('locale');
          this.cookieStorage.clearCookie(CookieKey.COOKIE_CONSENT);
        }
    });
  }

}
