import { Component, Input } from '@angular/core';

@Component({
  selector: 'invalid-token-template',
  templateUrl: './invalid-token-template.component.html',
  styleUrls: ['./invalid-token-template.component.scss']
})
export class InvalidTokenTemplateComponent {
  @Input() userRegistration = false;
}
