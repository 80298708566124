import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { environment } from 'environments/environment';
import * as moment from 'moment-mini-ts';
import { Subscription } from 'rxjs';
// import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
// import { AuthState, OktaAuth } from '@okta/okta-auth-js';
// import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { CookieStorage, HttpClientService } from './base/services';
import { CookieKey } from './config/config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnDestroy {
  private subscription: Subscription;
  public loadingLazyModule = false;
  constructor(
    @Inject('CommonObjects') commonObjects: any,
    @Inject(DOCUMENT) private doc: any,
    private router: Router,
    // private _oktaStateService: OktaAuthStateService, 
    // @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private oauthService: OAuthService,
    // public authStateService: OktaAuthStateService, 
    // @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
    private httpClientService: HttpClientService,
    private cookieStorage: CookieStorage
    ) {

    // update moment weekdays
    moment.updateLocale('en', {
      weekdays: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
      ]
    });

    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingLazyModule = true;
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingLazyModule = false;
      }
    });

    this.oauthService.redirectUri = environment.DASHBOARD_HOST_URL;
    
    // this.oauthService.clientId = '0oa5rm8jioTwX0TAm5d7';
    // this.oauthService.issuer = 'https://dev-43261493.okta.com/oauth2/default';

    this.oauthService.scope = 'openid profile email address phone';
    this.oauthService.responseType = 'id_token token';
    // this.oauthService.responseType = 'code';

    // this.oauthService.clientId = 'exk19v6vbsl5g4nj20h8';
    // this.oauthService.redirectUri = 'http://localhost:4200/';

    // this.oauthService.clientId = '0oa1aflbwdejh2c7d0h8';
    // this.oauthService.issuer = 'https://diversey.oktapreview.com/oauth2/default';
    
    this.oauthService.clientId = environment.OKTA_CLIENT_ID;
    this.oauthService.issuer = environment.OKTA_ISSUER;
    
    
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    // Load Discovery Document and then try to login the user
    // this.oauthService.loadDiscoveryDocument().then(() => {
    //   this.oauthService.tryLogin();
    // });
    this.oauthService.loadDiscoveryDocumentAndTryLogin();

    const claims = this.oauthService.getIdentityClaims();
    // this.setAccessToken();
    if(this.oauthService.getAccessToken() != undefined && this.oauthService.getAccessToken() != null) {
      this.setToken(this.oauthService.getAccessToken());
    }
    // eslint-disable-next-line angular/timeout-service
      window.setTimeout(() => {
      if(this.oauthService.getAccessToken() != undefined && this.oauthService.getAccessToken() != null) {
        this.setToken(this.oauthService.getAccessToken());
        // location.reload();
      }
    }, 1500);
    
    
  }

  async setAccessToken() {
    // console.log(' setAccessToken function');
    // eslint-disable-next-line angular/timeout-service
    window.setTimeout(() => {
      if(this.oauthService.getAccessToken() != undefined && this.oauthService.getAccessToken() != null) {
        // console.log('setAccessToken set access token');
        this.setToken(this.oauthService.getAccessToken());
      }
    }, 1500);
    
    // if(this.oauthService.getAccessToken() != undefined && this.oauthService.getAccessToken() != null) {
    //   console.log(' setAccessToken function set access token');
    //   this.setToken(this.oauthService.getAccessToken());
    // }
  }

  async ngOnInit() {
    this.cookieConsentCallback();
    await this.setAccessToken();
  }


  public setToken(token) {
    if (token) {
      this.cookieStorage.setCookie('token', token);
      // this.cookieStorage.setCookie('is_pass_expired', isPassExpired);
      this.httpClientService.updateHeaderToken(token);
    }
    
    if (this.cookieStorage.getCookie(CookieKey.NEW_DIVERSEY_USER) ) {
      const isNewDiverseyUser: boolean = this.cookieStorage.getCookie(CookieKey.NEW_DIVERSEY_USER) ?
    JSON.parse(this.cookieStorage.getCookie(CookieKey.NEW_DIVERSEY_USER)) : false;
        // isNewDiverseyUser ? this.router.navigate(['new-user-registration']) : this.router.navigate(['dashboard']);
        isNewDiverseyUser ? this.router.navigate(['new-user-registration']) : null;
        // isNewDiverseyUser ? console.log('isNewDiverseyUser : true', isNewDiverseyUser) : console.log('isNewDiverseyUser : false', isNewDiverseyUser);

    }

  }


  public async oktaLogin() : Promise<void> {
    // await this._oktaAuth.signInWithRedirect().then(
    //   _ => console.log('logged in success')
    // );
    // if (this._oktaAuth.isAuthenticated) {
    //   console.log('Autenticated');
    //   const token = await this._oktaAuth.tokenManager.get;
    //   console.log(token.length, token);
    //   const userClaims = await this._oktaAuth.getUser();
    //   console.log('User: ', userClaims); 
    // } else {
    //   console.log('not Authenticated');
    // }
    // console.log('Finish');
    // const userClaims = await this._oktaAuth.getUser();
    //   console.log('okta getUser: ', userClaims);
    //   const accessToken = await this._oktaAuth.getAccessToken();
    //   console.log('okta accessToken: ', accessToken, this._oktaAuth.getAccessToken());
     
  }

  cookieConsentCallback() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.textContent = `function OptanonWrapper() {
          var OptanonConsentcookie = ("; " + document.cookie).split("; OptanonConsent=").pop().split(";").shift();
          if (OptanonConsentcookie) {
              let consentInfo = decodeURIComponent(OptanonConsentcookie);
              let cutString = consentInfo.split('groups=');
              let consentData = cutString[1].split('&');
              if (consentData) {
                  let consentString = consentData[0].split(',');
                  let consentObj = {};
                  let consentValue1 = {
                      "C0001": 'Strictly Neccessary',
                      "C0002": 'Performance',
                      "C0003": 'Functional'
                  }
                  for (const each of consentString) {
                      let eachOne = each.split(':');
                      consentObj[eachOne[0].trim()] = Number(eachOne[1].trim());
                  }
                  let cookieConsentString = null;
                  for (const prop in consentObj) {
                      if (consentObj[prop] == 1) {
                          if (cookieConsentString != null) {
                              cookieConsentString = cookieConsentString + ',' + consentValue1[prop]
                          } else {
                              cookieConsentString = consentValue1[prop]
                          }
                      }
                  }
                  document.cookie = '${environment.COOKIE_PREFIX}' + 'cookie_consent = ' + cookieConsentString + ';domain=${environment.COOKIE_DOMAIN_NAME};';
              }
          }
      }`;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
