import { Injectable } from '@angular/core';
import { lables } from '../../../assets/i18n/error';
import { CookieStorage } from './cookie-storage.service';
import { locale } from '../../../assets/i18n/locale';
import { success } from '../../../assets/i18n/success';

@Injectable()
export class ToasterMessageService {
  private languages: any = new locale();
  private translateErrorsTo: any = new lables();
  private translateSuccessTo: any = new success();
  private locale = 'en-US';

  constructor(
    private cookieStorage: CookieStorage) {
    const value = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (value) {
      this.locale = this.languages['dictionary'][value];
    } else {
      this.locale = 'en-US';
    }
  }

  // TODO: checks if code is not their in our system then add fallbacks.
  getSuccessMessage(code) {
    return this.translateSuccessTo[code][this.locale];
  }

  getErrorMessage(code) {
    // console.log('toaster service called');
    if (typeof(code) === 'number') {
      return this.translateErrorsTo[code][this.locale];
    } else {
      this.returnFallbackMessage();
    }
  }

  // fallback if invalid/code returned from api's are not in our system
  returnFallbackMessage() {
    return this.translateErrorsTo[8005][this.locale];
  }

}
