import * as CryptoJS from 'crypto-js';

export function encryptPassword(passData, email) {
  const encrypt = CryptoJS.AES.encrypt(passData, email);
  return encrypt.toString();
}


export function haveDiverseyAccess(org_id) {
  return (org_id === 1);
}

export function isAdminUser(user_role_id) {
  return (user_role_id === 1 || user_role_id === 2);
}

export function isSiteUser(user_role_id) {
  return (user_role_id === 3);
}

export function isPowerAdmin(user_role_id) {
  return (user_role_id === 4);
}

export function isRootAdmin(id, user_role_id, accessible_nodes, org_id) {
  return (id ===1 && user_role_id === 1 && accessible_nodes === null && org_id === 1);
}

export function isSubAdminUser(accessible_nodes) {
  return (accessible_nodes !== null && Array.isArray(accessible_nodes) && accessible_nodes.length > 0);
}

export function isDiverseyAdmin(user_role_id, accessible_nodes, org_id) {
  return ((user_role_id === 1 || user_role_id === 2) && accessible_nodes === null && org_id === 1);
}

export function isOrgSubAdmin(user_role_id, accessible_nodes) {
  return (user_role_id === 2 && accessible_nodes !== null);
}
