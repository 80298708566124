import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment-mini-ts';

@Injectable()
export class CommonService {
  public clientLogo: any;
  public _solutionSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public clientLogoSubscription: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() { /** */ }

  /* get groupby param from start and end time - calculating difference */
  getGroupByParam(time) {
    let groupBy = 'hours';
    const start = moment(time.start, 'YYYY-MM-DD');
    const end = moment(time.end, 'YYYY-MM-DD');
    const dayDiff = end.diff(start, 'days');
    // Now get the groupBy param
    if (dayDiff > 365) {
      groupBy = 'years';
    } else if (dayDiff > 31) {
      groupBy = 'months';
    } else if (dayDiff > 7 && dayDiff <= 31) {
      groupBy = 'days';
    } else if (dayDiff > 1 && dayDiff <= 7) {
      groupBy = 'week';
    }
    return groupBy;
  }

  /* get format based on groupby param - contains startOf param and format String */
  getGroupByFormat(groupByParam) {
    const formatObj = { startOf: 'day', formatStr: 'DD-MMM' };
    switch (groupByParam) {
    case 'months':
      formatObj.startOf = 'month';
      formatObj.formatStr = 'MMM-YYYY';
      break;
    case 'years':
      formatObj.startOf = 'year';
      formatObj.formatStr = 'YYYY';
      break;
    case 'hours':
      formatObj.startOf = 'hour';
      formatObj.formatStr = 'HH';
      break;
    // Changed the logic to show dates instead of week names
    case 'week':
      formatObj.startOf = 'day';
      formatObj.formatStr = 'DD-MMM';
      break;
    // Default case added
    default:
      formatObj.startOf = 'day';
      formatObj.formatStr = 'DD-MMM';
    }
    return formatObj;
  }

  /* get formated time based on groupby param and format accordingly - optional getTimestamp */
  getGroupByTime(date, formatObj, getTimestamp = false) {
    const numValue = date / 1;
    if (numValue === numValue) {
      date = numValue;
    }
    const time = moment(date).startOf(formatObj.startOf);
    return (getTimestamp ? (+time) : time.format(formatObj.formatStr));
  }

  /* callback for compare function in sorting time array - if not timestamps, provide format string */
  sortTimeCallback(currentValue, nextValue, formatStr = null) {
    const numCurrentValue = currentValue / 1;
    if (numCurrentValue === numCurrentValue) {
      return currentValue - nextValue;
    } else {
      formatStr = formatStr || 'DD-MMM';
      return (moment(currentValue, formatStr).diff(moment(nextValue, formatStr)));
    }
  }
  emitSolutionName(solutionName) {
    this._solutionSubject.next(solutionName);
  }

  /* for clearing subscriptions : input - comma seperated Subscriptions */
  clearSubscription(...subscriptions: Subscription[]): void {
    if (!subscriptions) {
      return;
    }
    for (let i = 0; i < subscriptions.length; ++i) {
      if (subscriptions[i]) {
        subscriptions[i].unsubscribe();
      }
    }
  }
}
