import { Injectable } from '@angular/core';

import { CookieStorage } from './cookie-storage.service';
import { UserService } from './user.service';
import { LocalDataStoreService } from './local-data-store.service';
import { of } from 'rxjs';

@Injectable()
export class LocalDataResolver  {

  constructor(
    private cookieStorage: CookieStorage,
    private userService: UserService,
    private localDataStoreService: LocalDataStoreService
  ) { }

  resolve() {
    if (this.cookieStorage.checkCookieAvailability('token') && !this.localDataStoreService.userDetails) {
      return this.userService.getUserDetails();
    }
    return of(null);
  }
}
