import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { encryptPassword } from 'app/base/utils';
import { LocalDataStoreService, UserService, LoaderService, ToasterMessageService, CookieStorage } from 'app/base/services';
import { ToastrService } from 'ngx-toastr';
import { locale } from '../../assets/i18n/locale';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['../base/components/user-profile/user-profile.component.scss']
})
export class DeactivateAccountComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  public deactivateForm: UntypedFormGroup;
  public country_locale = new locale();
  public locale;
  private userEmail: string;
  private userID: number;
  private user_role_id: any;
  loader = false;
  formSubmitted = false;
  window: Window & typeof globalThis;

  public isOktaLogin = false;

  constructor(
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private toasterMessageService: ToasterMessageService,
    private _fB: UntypedFormBuilder,
    private localDataStoreService: LocalDataStoreService,
    private cookieStorage: CookieStorage,
    private translate: TranslateService,
    private _userService: UserService,
    @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    const userLocale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (userLocale && this.country_locale.dictionary[userLocale]) {
      this.locale = this.country_locale.dictionary[userLocale];
      this.translate.use(this.locale);
    }
  }
  ngOnInit() {
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });

    this.deactivateForm = this._fB.group({
      password: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[\w.'_+-]+@[\w'._+-]+?(\.[a-zA-Z]{2,10}){1,3}$/)]]
      // email: ['', Validators.required, Validators.minLength(3), Validators.email]
    });

    this.localDataStoreService.currentUserState.subscribe((res) => {
      if (res) {
        this.userEmail = res['email'] || null;
        this.user_role_id = res['user_role_id'];
        this.userID = res['id'];
        this.isOktaLogin = res['is_okta_login'];
      }
    });
  }

  get password() {
    return this.deactivateForm.get('password') as UntypedFormControl;
  }

  get email() {
    return this.deactivateForm.get('email') as UntypedFormControl;
  }

  onSubmitDeactivateAccount(passData) {
    this.formSubmitted = true;
    if (this.isOktaLogin) {
      this.deactivateWithEmail(passData['email']);
    } else {
      const password = encryptPassword(passData['password'], this.userEmail);
      const payload = {
        userid: this.userID, password: password
      }

    this.loaderService.show();
    this._userService.deactivateAccount(payload).subscribe((res) => {
      const message = this.toasterMessageService.getSuccessMessage(8006);
      message ? this.toasterService.success(message) : null;

      this.loaderService.hide();
      this.notify.emit();

      this.window.setTimeout(() => {
        this._userService.logout();
      }, 1000);
    }, (err) => {
      this.loaderService.hide();
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toasterService.error(message) : null;
      }
    });
    }

  }

  public deactivateWithEmail(email) {
    const payload = {
      is_active: false, user_id: this.userID, email: email
    }
    this.loaderService.show();
    this._userService.deactivateAccountWithEmail(payload).subscribe((res) => {
      const message = this.toasterMessageService.getSuccessMessage(8006);
      message ? this.toasterService.success(message) : null;
      this.loaderService.hide();
      this.notify.emit();
      this.window.setTimeout(() => {
        this._userService.logoutOkta(email);
      }, 1000);
    }, (err) => {
      this.loaderService.hide();
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toasterService.error(message) : null;
      }
    });
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }
}
