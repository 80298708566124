import { localStorageMockData } from '../fixtures';

export class MockLocalStorageServiceHandler {
  get(key?): string {
    return localStorageMockData.globalAccount;
  }

  set(key, value) { /** */ }

  clearAll() {
    // Local Storage should clear all
    // do nothing but mock it.
  }
}
