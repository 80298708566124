import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieStorage } from 'app/base/services/cookie-storage.service';

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router,
    private cookieStorage: CookieStorage) {
  }

  canActivate(): boolean {
    const token: boolean = this.cookieStorage.checkCookieAvailability('token');
    if (!token) {
      console.log('Token or user data missing logging out the user!');
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
}
