export class flags {
  dictionary: any = {
    'en-AU': 'Australia',
    'en-BZ': 'Belize',
    'en-CA': 'Canada',
    'en-CB': 'Caribbean',
    'en-US': 'United States',
    'en-GB': 'Great Britain',
    'en-IN': 'India',
    'en-IE': 'Ireland',
    'en-JM': 'Jamaica',
    'en-NZ': 'New Zealand',
    'en-PH': 'Philippines',
    'en-ZA': 'Southern Africa',
    'en-TT': 'Trinidad',
    'nl-NL': 'Netherlands',
    'nl-BE': 'Belgium',
    'fr-BE': 'Belgium',
    'fr-FR': 'France',
    'fr-LU': 'Luxembourg',
    'fr-MA': 'Morocco',
    'fr-CH': 'Switzerland',
    'de-AT': 'Austria',
    'de-DE': 'Germany',
    'de-LI': 'Liechtenstein',
    'de-LU': 'Luxembourg',
    'de-CH': 'Switzerland',
    'pl-PL': 'Poland',
    'zh-ZH': 'China',
    'it-IT': 'Italy',
    'it-CH': 'Switzerland',
    'tr-TR': 'Turkey',
    'fi-FI': 'Finland',
    'da-DK': 'Denmark',
    'da-DA': 'Denmark',
    'ar-AE': 'UAE',
    'ar-SA': 'Saudi Arabia',
    'ar-BH': 'Bahrain',
    'pt-PT': 'Portugal',
    'pt-BR': 'Brazil',
    'es-ES': 'Spain',
    'es-AR': 'Argentina',
    'es-CL': 'Chile',
    'es-MX': 'Mexico',
    'sv-SE': 'Sweden',
    'sv-FI': 'Finland',
    'hu-HU': 'Hungary',
    'el-EL': 'Greece',
    'no-NO': 'Norway',
    'ru-RU': 'Russia',
    'cs-CS': 'Czech Republic',
    'sl-SL': 'Solvenia',
    'ko-KO': 'Korea',
    'in-ID': 'Indonesia',
    'en-MT': 'Malta',
    'si-LK': 'Sri Lanka',
    'bg-BG': 'Bulgaria',
    'sk-SK': 'Slovakia',
    'en-EE': 'Estonia',
    'en-LV': 'Latvia',
    'en-BY': 'Belarus',
    'en-UA': 'Ukraine',
    'en-HR': 'Croatia',
    'es-DO': 'Dominican Republic',
    'en-SC': 'Seychelles',
    'fr-SC': 'Seychelles',
    'en-BS': 'Bahamas'
  };
}
