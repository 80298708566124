import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { UserService, CookieStorage, LocalDataStoreService, ToasterMessageService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../../../assets/i18n/locale';
import { success } from '../../../../assets/i18n/success';
import { lables } from '../../../../assets/i18n/error';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-terms-conditions-acceptance',
  templateUrl: './terms-conditions-acceptance.component.html',
  styleUrls: ['./terms-conditions-acceptance.component.scss']
})
export class TermsConditionsAcceptanceComponent implements OnInit {
  public decline = false;
  public country_locale = new locale();
  public success: success = new success();
  public error: lables = new lables();
  public locale;
  userData: Record<string, unknown> = null;
  @Output() updateTerms: EventEmitter<any> = new EventEmitter();
  @Input() isLoading: boolean;

  constructor(private toaster: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private cookieStorage: CookieStorage,
    private toasterMessageService: ToasterMessageService,
    private localDataStoreService: LocalDataStoreService) {
    const userLocale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (userLocale) {
      this.locale = this.country_locale.dictionary[userLocale] || null;
    }
    if (this.locale) {
      this.translate.use(this.locale);
    }
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.localDataStoreService.userDetails) {
      this.userData = this.localDataStoreService.userDetails;
      this.isLoading = false;
    } else {
      this.userService.getUserDetails().subscribe((data) => {
        if (data) {
          this.userData = data;
        }
        this.isLoading = false;
      });
    }

    this.localDataStoreService.currentUserState.subscribe((res) => {
      const userData = res;
        if(userData) {
          this.userData = userData;
        }
    });
  }

  onAccept() {
    this.isLoading = false;
    this.userService.updateTermsAndCondition().subscribe((res) => {
      if (res && res.success) {
        this.updateTerms.emit(true);
        this.updateUserLocalStore();
        const message = this.toasterMessageService.getSuccessMessage(8004);
        message ? this.toaster.info(message) : null;
      }
      this.isLoading = true;
    }, (err) => {
      if ((err.error.success === false) && (err.error.message === 'Terms and Conditions already accepted')) {
        this.updateTerms.emit(true);
        this.updateUserLocalStore();
        const message = this.toasterMessageService.getErrorMessage(1009);
        message ? this.toaster.info(message) : null;
      } else {
        this.updateTerms.emit(false);
        const errCode = this.getErrorCode(err);
        if (errCode) {
          const message = this.toasterMessageService.getErrorMessage(errCode);
          message ? this.toaster.info(message) : null;
        }
      }
      this.isLoading = false;
    });
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  updateUserLocalStore() {
    const tcAccepted = this.userData ? this.userData['term_condition_accepted'] : null;
    if (this.userData && (tcAccepted === false)) {
      this.userData['term_condition_accepted'] = true;
      this.localDataStoreService.updateData(this.userData);
    }
  }

  onDecline() {
    this.decline = !this.decline;
  }

  onOk() {
    this.decline = !this.decline;
  }
}
