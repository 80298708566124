import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CookieStorage } from '../../../base/services/cookie-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../../../assets/i18n/locale';
import { LocalDataStoreService } from 'app/base/services';
import { isPowerAdmin, isRootAdmin } from 'app/base/utils';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  // event emitter to destroy self
  @Output() closeSelf: EventEmitter<any> = new EventEmitter();
  @Output() isProfileTab: EventEmitter<any> = new EventEmitter();
  @Input() isProfileTabActive = true;
  @Input() isDeactivateTabActive = false;
  @Input() isAdminUser = false;
  @Input() isUpdateEmailTab = false;
  

  public isDiverseyUser = false;
  public isOktaLogin = false;
  userData: any;

  public country_locale = new locale();
  constructor(
    private cookieStorage: CookieStorage,
    private translate: TranslateService, 
    private localDataStoreService: LocalDataStoreService
    ) {
    let locales = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (locales && this.country_locale.dictionary[locales]) {
      locales = this.country_locale.dictionary[locales];
      translate.use(locales);
    }
    if(this.localDataStoreService.userDetails) {
      if (this.localDataStoreService.userDetails.is_okta_login) {
        this.isOktaLogin = this.localDataStoreService.userDetails.is_okta_login;
      } else {
        this.isOktaLogin = false;
      }
     
      this.isDiverseyUser = (this.localDataStoreService.userDetails.email.includes('@diversey.com')) ? true : false;
    }

    this.localDataStoreService.currentUserState.subscribe((res) => {
      this.userData = res;
    });
    
  }

  collapse() {
    this.closeSelf.emit(null);
  }

  showContentOf(tab, event) {
    if (tab === 'profile') {
      this.isProfileTabActive = true;
      this.isDeactivateTabActive = false;
      this.isUpdateEmailTab = false;
      this.isProfileTab.emit('profile');
    } else if (tab === 'password') {
      this.isProfileTabActive = false;
      this.isDeactivateTabActive = false;
      this.isUpdateEmailTab = false;
      this.isProfileTab.emit('reset');
    } else if (tab === 'deactivate') {
      this.isProfileTabActive = false;
      this.isDeactivateTabActive = true;
      this.isUpdateEmailTab = false;
      this.isProfileTab.emit('deactivate');
    } else if (tab === 'updateEmail') {
      this.isProfileTabActive = false;
      this.isDeactivateTabActive = false;
      this.isUpdateEmailTab = true;
      this.isProfileTab.emit('updateEmail');
    }
  }

  checkDiverseyRootOrPowerAdmin() {
    const { id, accessible_nodes, org_id, user_role_id } = this.userData;
    return (isPowerAdmin(this.userData.user_role_id) || isRootAdmin(id, user_role_id, accessible_nodes, org_id));
  }

}
