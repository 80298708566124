export class CommonService {

  checkConnectionIssue(responseStatus) {
    if (responseStatus === 500) {
      return true;
    }
    return false;
  }
  checkEmptyResponse(response) {
    if (response && response.status === 204) {
      return true;
    }
    return false;
  }

  generateRandomPassword() {
    return this.generatePassword(this.getRandomArbitrary(15, 20))
  }

  getRandomArbitrary(min, max) {
    return Math.ceil(Math.random() * (max - min) + min);
  }

  generatePassword(length) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classObj = this;
    if (!length || length == undefined) {
      length = 8;
    }
    const rules = [
      { chars: "abcdefghijklmnopqrstuvwxyz", min: 3 },  // As least 3 lowercase letters
      { chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 2 },  // At least 2 uppercase letters
      { chars: "0123456789", min: 2 },                  // At least 2 digits
      { chars: "~!@-#$_%*&?", min: 1 }      // At least 1 special char
    ];

    let allChars = "", allMin = 0;
    rules.forEach(function (rule) {
      allChars += rule.chars;
      allMin += rule.min;
    });
    if (length < allMin) {
      length = allMin;
    }
    rules.push({ chars: allChars, min: length - allMin });
    let pswd = "";
    rules.forEach(function (rule) {
      if (rule.min > 0) {
        pswd += classObj.shuffleString(rule.chars, rule.min);
      }
    });
    return this.shuffleString(pswd, length);
  }

  shuffleString(str, maxlength) {
    let shuffledString = str.split('').sort(function () { return 0.5 - Math.random() }).join('');
    if (maxlength > 0) {
      shuffledString = shuffledString.substr(0, maxlength);
    }
    return shuffledString;
  }

}
