const response200 = {
  global_account: {
    id: 1,
    name: 'Global Account 1'
  },
  hierarchy: [
    {
      id: 11,
      name: 'Asia',
      subordinates: [
        {
          id: 21,
          name: 'India',
          subordinates: [
            {
              id: 31,
              name: 'Goa',
              sites: [
                {
                  id: '811',
                  name: 'Site 1'
                },
                {
                  id: '812',
                  name: 'Site 2'
                }
              ]
            },
            {
              id: 32,
              name: 'Maharashtra',
              sites: [
                {
                  id: '813',
                  name: 'Site 1'
                },
                {
                  id: '814',
                  name: 'Site 2'
                }
              ]
            }
          ]
        },
        {
          id: 41,
          name: 'Japan',
          subordinates: [
            {
              id: 51,
              name: 'Tokyo',
              sites: [
                {
                  id: '815',
                  name: 'Site 1'
                },
                {
                  id: '816',
                  name: 'Site 2'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 12,
      name: 'Africa',
      subordinates: [
        {
          id: 22,
          name: 'South Africa',
          subordinates: [
            {
              id: 32,
              name: 'Kimberley',
              sites: [
                {
                  id: '817',
                  name: 'Site 1',
                },
                {
                  id: '818',
                  name: 'Site 2',
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 13,
      name: 'Europe',
      subordinates: [
        {
          id: 23,
          name: 'Germany',
          subordinates: [
            {
              id: 33,
              name: 'Hamburg',
              subordinates: [
                {
                  id: 41,
                  name: 'NieNieNien',
                  sites: [
                    {
                      id: '819',
                      name: 'Site 1',
                    },
                    {
                      id: '901',
                      name: 'Site 2',
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export const singleAccountDropdown = {
  response200: response200
};

export const hierarchyData = [
  {
    'id': 'cm123',
    'name': 'Wallmart',
    'parent_id': null,
    'breadcrumbs': 'cm123',
    'is_deleted': 0,
    'path_length': 0,
    'type': 'company',
    'children': [
      {
        'id': 'cl121',
        'name': 'Asia',
        'parent_id': 'cm123',
        'breadcrumbs': 'cm123,cl121',
        'is_deleted': 0,
        'path_length': 1,
        'type': 'region',
        'children': [
          {
            'id': 'cl234',
            'name': 'India',
            'parent_id': 'cl121',
            'breadcrumbs': 'cm123,cl121,cl234',
            'is_deleted': 0,
            'path_length': 2,
            'type': 'country',
            'children': [
              {
                'id': 'cl345',
                'name': 'Goa',
                'parent_id': 'cl234',
                'breadcrumbs': 'cm123,cl121,cl234,cl345',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's01001',
                    'name': 'Site 1001',
                    'parent_id': 'cl345',
                    'breadcrumbs': 'cm123,cl121,cl234,cl345,s01001',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's01002',
                    'name': 'Site 1002',
                    'parent_id': 'cl345',
                    'breadcrumbs': 'cm123,cl121,cl234,cl345,s01002',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              },
              {
                'id': 'cl356',
                'name': 'Maharashtra',
                'parent_id': 'cl234',
                'breadcrumbs': 'cm123,cl121,cl234,cl356',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's01003',
                    'name': 'Site 1003',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01003',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's01004',
                    'name': 'Site 1004',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01004',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              }
            ]
          },
          {
            'id': 'cl245',
            'name': 'Bhutan',
            'parent_id': 'cl121',
            'breadcrumbs': 'cm123,cl121,cl245',
            'is_deleted': 0,
            'path_length': 2,
            'type': 'country',
            'children': [
              {
                'id': 'cl356',
                'name': 'Kumfu',
                'parent_id': 'cl121',
                'breadcrumbs': 'cm123,cl121,cl245,cl356',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's01005',
                    'name': 'Site 1005',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01005',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's01006',
                    'name': 'Site 1006',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01006',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              },
              {
                'id': 'cl367',
                'name': 'Lamadi',
                'parent_id': 'cl121',
                'breadcrumbs': 'cm123,cl121,cl234,cl367',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's01007',
                    'name': 'Site 1007',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01007',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's01008',
                    'name': 'Site 1008',
                    'parent_id': 'cl356',
                    'breadcrumbs': 'cm123,cl121,cl234,cl356,s01008',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'id': 'cl123',
        'name': 'Europe',
        'parent_id': 'cl356',
        'breadcrumbs': 'cm123,cl123',
        'is_deleted': 0,
        'path_length': 1,
        'type': 'region',
        'children': [
          {
            'id': 'cl298',
            'name': 'Estonia',
            'parent_id': 'cl356',
            'breadcrumbs': 'cm123,cl123,cl298',
            'is_deleted': 0,
            'path_length': 2,
            'type': 'country',
            'children': [
              {
                'id': 'cl387',
                'name': 'Tartu',
                'parent_id': 'cl298',
                'breadcrumbs': 'cm123,cl123,cl298,cl387',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's02001',
                    'name': 'Site 2001',
                    'parent_id': 'cl387',
                    'breadcrumbs': 'cm123,cl121,cl234,cl387,s02001',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's02002',
                    'name': 'Site 2002',
                    'parent_id': 'cl387',
                    'breadcrumbs': 'cm123,cl121,cl234,cl387,s02002',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              },
              {
                'id': 'cl376',
                'name': 'Mardu',
                'parent_id': 'cl298',
                'breadcrumbs': 'cm123,cl123,cl298,cl376',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's02003',
                    'name': 'Site 2003',
                    'parent_id': 'cl376',
                    'breadcrumbs': 'cm123,cl123,cl298,cl376,s02003',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's02004',
                    'name': 'Site 2004',
                    'parent_id': 'cl376',
                    'breadcrumbs': 'cm123,cl123,cl298,cl376,s02004',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              }
            ]
          },
          {
            'id': 'cl287',
            'name': 'Spain',
            'parent_id': 'cl356',
            'breadcrumbs': 'cm123,cl356,cl287',
            'is_deleted': 0,
            'path_length': 2,
            'type': 'country',
            'children': [
              {
                'id': 'cl365',
                'name': 'Ronda',
                'parent_id': 'cl287',
                'breadcrumbs': 'cm123,cl356,cl287,cl365',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's02005',
                    'name': 'Site 2005',
                    'parent_id': 'cl376',
                    'breadcrumbs': 'cm123,cl356,cl287,cl365,s02005',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's02006',
                    'name': 'Site 2006',
                    'parent_id': 'cl376',
                    'breadcrumbs': 'cm123,cl356,cl287,cl365,s02006',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              },
              {
                'id': 'cl354',
                'name': 'Aragon',
                'parent_id': 'cl287',
                'breadcrumbs': 'cm123,cl356,cl287,cl354',
                'is_deleted': 0,
                'path_length': 3,
                'type': 'state',
                'sites': [
                  {
                    'id': 's02007',
                    'name': 'Site 2007',
                    'parent_id': 'cl287',
                    'breadcrumbs': 'cm123,cl356,cl287,cl354,s02005',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  },
                  {
                    'id': 's02008',
                    'name': 'Site 2008',
                    'parent_id': 'cl287',
                    'breadcrumbs': 'cm123,cl356,cl287,cl354,s02005',
                    'is_deleted': 0,
                    'path_length': 4,
                    'type': 'site'
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
