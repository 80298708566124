import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';
import { HttpClientService } from './http.client-module.service';
import { LocalStorageService } from './localStorage.service';
import { Subject } from 'rxjs';
import { CommonService } from 'app/base/services/common.service';
import { LoginData } from 'app/base/models/login.data';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import 'rxjs/add/observable/throw';
import { BehaviorSubject } from 'rxjs';
import { LocalDataStoreService } from './local-data-store.service';
import { CookieStorage } from './cookie-storage.service';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { CookieKey } from 'app/config/config';
@Injectable()
export class UserService {
  public selectedOrgSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public orgSelectionLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loggedIn = false;
  private email = '';
  public userNameSubject = new Subject();
  public loginData: LoginData;

  private URLS = {
    login: `${environment.NODE_URL}auth/login`,
    countries: `${environment.NODE_URL}common/countries`,
    languages: `${environment.NODE_URL}common/languages`,
    orgList: `${environment.NODE_URL}organization/list`,
    orgListAll: `${environment.NODE_URL}organization/list/all`,
    org: `${environment.NODE_URL}organization/`,
    user: `${environment.NODE_URL}user/`,
    logout: `${environment.NODE_URL}auth/logout`,
    clearcache: `${environment.NODE_URL}auth/clearcache`,
    forgotPassword: `${environment.NODE_URL}auth/forgot-password/`,
    validateForgotPasswordToken: `${environment.NODE_URL}auth/forgot-password/validate/`,
    validateUpdateEmailToken: `${environment.NODE_URL}auth/update-email/validate/`,
    validateUpdateEmailTokenWithEmail: `${environment.NODE_URL}auth/update-email/validate-email/`,
    updateEmailWithPassword: `${environment.NODE_URL}auth/update-email/`,
    updateemail: `${environment.NODE_URL}user/update-email/`,
    solutions: `${environment.NODE_URL}common/solutions`,
    termsCondition: `${environment.NODE_URL}term-condition`,
    solutionTileInfo: `${environment.NODE_URL}solution-overview`,
    deactivate: `${environment.NODE_URL}users/action`,
    deactivatewithemail: `${environment.NODE_URL}users/action-email`,
    solutionAceess: `${environment.NODE_URL}user/request_solution_access`,
    // getAllSolutionRequests: `${environment.NODE_URL}user/list_all_solution_requests`
    getAllSolutionRequests: `${environment.NODE_URL}user/list/all_solution_requests`,
    getSolutionsWithAllowRequest: `${environment.NODE_URL}common/solutions-with-allow-request`
    
  };

  public solution_req_org: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private httpClientService: HttpClientService,
    private router: Router,
    private localDataStoreService: LocalDataStoreService,
    private cookieStorage: CookieStorage,
    private oauthService: OAuthService
    ) {
  }

  getLocales() {
    return this.httpClientService
      .getDataWithNoAuth(`${this.URLS.languages}`)
      .pipe(
        map((res) => res),
        catchError((err) => this.handleError(err))
      );
  }

  emitOrgRequestToSol(data) {
    this.solution_req_org.next(data);
  }

  getCompany(companyId) {
    return of(1);
  }
  syncLanguage(lang) {
    if (lang) {
      this.cookieStorage.setCookie('locale', lang);
    }
  }

  logout(reload = true, email = null) {
    if(email != null) {
      this.clearcache(email).subscribe();
    }
    return this.httpClientService
      .get(this.URLS.logout)
      .pipe(
        map(res => res),
        catchError(this.handleError)
      ).subscribe(() => this.updateLocalStorage(reload));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  logout_with_idp() {
    return this.httpClientService
      .post(this.URLS.logout, { email: '' })
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  clearcache(email) {
    const body = {
      email : email
    };
    return this.httpClientService.post(this.URLS.clearcache, body)
      .pipe(map((res) => res));
  }

  updateLocalStorage(reload) {
    this.localDataStoreService.updateData(null);
    this.localDataStoreService.updateSelectedOrgDetails(null);
    this.cookieStorage.clearCookie('token');
    this.localDataStoreService.updateOrgChildrens(null);
    this.localDataStoreService.updateOrganizations(null);
    this.clearStorage(reload);
  }

  async logoutOkta(email = null) {
    this.updateLocalStorage(false);
    if(email != null) {
      this.clearcache(email).subscribe();
    }
    await this.oauthService.logOut();
  }

  clearStorage(reload) {
    this.httpClientService.deleteToken();
    // update next value in userNameSubject to null
    this.userNameSubject.next(null);
    const cookieConsentValue = this.localStorageService.get('cookies_consent_accepted');
    const locale = this.cookieStorage.getCookie('locale');
    this.localStorageService.clearAll();
    // preserving value
    if (cookieConsentValue) {
      this.localStorageService.set('cookies_consent_accepted', cookieConsentValue);
    }
    // preserve user preferred language
    if (locale) {
      this.cookieStorage.setCookie('locale', locale);
    }
    this.loggedIn = false;
    if (reload) {
      // this.router.navigate(['']);
      // bug fix by moving to login page on logout
      this.router.navigate(['login']);

    }
    this.commonService.clientLogoSubscription.next(null);
  }

  login(user): Observable<any> {
    const loginData = {
      email: user.email.toLowerCase(),
      password: user.password
    };
    return this.httpClientService.post(this.URLS.login, loginData)
      .pipe(map((response: LoginData) => {
        this.loginData = response;
        if (this.loginData.token) {
          this.email = user.email;
          this.setToken(this.loginData.token, this.loginData.is_pass_expired);
        }
      }, (err) => {
        this.userNameSubject.next(null);
      }),
        mergeMap(() => {
          return this.getUserDetails();
        }),
        mergeMap((userData) => {
          return this.getLocaleDetails(userData);
        }));
  }

  // to get email for password reset
  forgotPassword(email: string): Observable<any> {
    const body = {
      email : email
    };
    return this.httpClientService.post(this.URLS.forgotPassword, body)
      .pipe(map((res) => res));
  }

  // update password with forgot-pass-token link
  forgotPasswordUpdate(token, password): Observable<any> {
    return this.httpClientService
      .putWithNoAuth(`${this.URLS.forgotPassword}${token}`, { password })
      .pipe(map((res) => res));
  }

  resetPassword(data): Observable<any> {
    return this.httpClientService.put(`${this.URLS.user}reset-password`, data)
      .pipe(map((updatedUser) => updatedUser));
  }

  setToken(token, isPassExpired) {
    if (token) {
      this.cookieStorage.setCookie('token', token);
      this.cookieStorage.setCookie('is_pass_expired', isPassExpired);
      this.httpClientService.updateHeaderToken(token);
    }
  }

  getOrganizations() {
    let userData;
    this.localDataStoreService.currentUserState.subscribe((res) => userData = res);
    const roleId = userData ? userData['user_role_id'] : null;
    if (roleId === 1) {
      return this.httpClientService.get(this.URLS.orgListAll)
        .pipe(map((orgList) => orgList),
          catchError(this.handleError));
    } else {
      const search = { search: {} };
      return this.httpClientService.post(this.URLS.orgList, search)
        .pipe(map((orgListAll) => orgListAll),
          catchError(this.handleError));
    }
  }

  updateProfile(user) {
    return this.httpClientService.put(`${this.URLS.user}`, user)
      .pipe(map((updatedUser) => updatedUser),
        catchError(this.handleError));
  }

  getCommonData(dataUrl) {
    if (this.URLS[dataUrl]) {
      return this.httpClientService.getDataWithNoAuth(`${this.URLS[dataUrl]}`)
        .pipe(map((data) => data),
          catchError(this.httpClientService.handleError));
    }
  }

  getOrgData(orgId) {
    return this.httpClientService.get(`${this.URLS.org}${orgId}`)
      .pipe(map((orgList) => orgList));
  }

  getUserDetails() {
    return this.httpClientService.get(`${this.URLS.user}`)
      .pipe(
        map((userData) => {
          if (userData && userData['data']) {
            if(userData['data']['newDiverseyUser']) {
              this.cookieStorage.setCookie(CookieKey.NEW_DIVERSEY_USER, userData['data']['newDiverseyUser']);
              this.localDataStoreService.updateData(userData['data']);
              return userData['data'];
            } else {
              this.cookieStorage.setCookie(CookieKey.NEW_DIVERSEY_USER, false);
              this.cookieStorage.setCookie(CookieKey.LOCALE, userData['data']['locale']);
              this.localDataStoreService.updateData(userData['data']);
              return userData['data'];
            }
            
          } else {
            console.log('Error!! User data not found!!');
          }
        })
      );
  }

  getLocaleDetails(userData) {
    if (userData) {
      this.cookieStorage.setCookie('locale', userData.locale);
      return this.httpClientService.get(`${this.URLS.languages}/${userData.locale}`)
        .pipe(
          map((localeDetails) => {
            userData.locale_details = localeDetails['data'];
            this.userNameSubject.next(userData['username']);
            this.localDataStoreService.updateData(userData);
          }),
          catchError(this.handleError)
        );
    }
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  getCountries(): Observable<any> {
    return this.httpClientService.get(this.URLS.countries)
      .pipe(map((res: Response) => <any>res.json()),
        catchError(this.handleError));
  }

  getSolutions(){
    return this.httpClientService.get(this.URLS.getSolutionsWithAllowRequest)
      .pipe(map((res: Response) => res),
        catchError(this.handleError));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendSolutionAccessRequest(solutions: any) {
    
    return this.httpClientService
      .post(`${this.URLS.solutionAceess}`, solutions)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  // list_all_solution_requests
  getAllSolutionRequests() {
    // console.log('User getAllSolutionRequests: ');
    return this.httpClientService
      .get(this.URLS.getAllSolutionRequests)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  private handleError(error: Response) {
    return throwError(error || 'Server Error');
  }

  /** ======================Temperature Monitoring  START ====================== */
  getHierarchy() {
    let userData;
    this.localDataStoreService.currentUserState.subscribe((res) => userData = res);
    return this.httpClientService.get(`${environment.NODE_URL}hierarchy/${userData.organization.node_id}`)
      .pipe(catchError(this.handleError));
  }

  /** ======================Temperature Monitoring  END ====================== */

  getTerms(id): Observable<any> {
    return this.httpClientService.get(`${this.URLS.termsCondition}/${id}`)
      .pipe(map((res: Response) => res),
        catchError(this.httpClientService.handleError));
  }

  updateTermsAndCondition(): Observable<any> {
    return this.httpClientService.put(`${this.URLS.user}accept/terms-and-conditions`, {})
      .pipe(map((updatedUser) => updatedUser));
  }

  updateCookieConsent(value): Observable<any> {
    return this.httpClientService.post(`${this.URLS.user}accept/cookie-consent`, {consent: value})
      .pipe(map((updatedUser) => updatedUser));
  }

  getImmediateOrgChild(nodeId) {
    const url = `${environment.NODE_URL}hierarchy/immediate/child/${nodeId}`;
    return this.httpClientService.get(url).pipe(map(response => response));
  }

  getTileData(id, solution) {
    // get solution tile data
    return this.httpClientService
      .post(`${this.URLS.solutionTileInfo}`, { id, solution })
      .pipe(
        map((tileData) => tileData),
        catchError((err, caught) => {
          return throwError(this.dummyTileData(solution));
        })
      );
  }

  getOrgSites(nodeId) {
    return this.httpClientService.get(`${this.URLS.org}`)
      .pipe(
        map((siteList) => siteList),
        catchError((err, caught) => {
          return throwError(err.error);
        })
      );
  }

  setOrgSelectionLoader(value) {
    this.orgSelectionLoader.next(value);
  }

  setSelectedOrg(orgData) {
    this.selectedOrgSubject.next(orgData);
  }

  dummyTileData(solution) {
    const sampleResult = {
      'Device Management': { color: '#66bb6a', icon: 'assets/images/tracebility.png', redirectTo: '' },
      'Food Safety Monitoring': { color: '#ffab00', icon: 'assets/images/Food Safety Monitoring.png', redirectTo: '' },
      'IntelliCare': { color: '#ffc107', icon: 'assets/images/IntelliCare.png', redirectTo: '' },
      'IntelliDish': { color: '#ff5252', icon: 'assets/images/IntelliDish.png', redirectTo: '' },
      'IntelliLinen': { color: '#66bb6a', icon: 'assets/images/IntelliLinen.png', redirectTo: '' },
      'IntelliTrail': {
        color: '#FFC200',
        icon: 'assets/images/IntelliTrail.png',
        redirectTo: this.getEnvSpecificLink('intellitrail.diverseyiot.com')
      },
      'IntelliTrak': { color: '#ffab00', icon: 'assets/images/IntelliTrak.png', redirectTo: '' },
      'Time & Task': {
        color: '#ff5252',
        icon: 'assets/images/Time & Task.png',
        redirectTo: this.getEnvSpecificLink('timeandtask.diverseyiot.com')
      },
      'Temp Monitoring': {
        color: '#66bb6a',
        icon: 'assets/images/Temp Monitoring.png',
        redirectTo: this.getEnvSpecificLink('tempmon.diverseyiot.com')
      }
    };
    return sampleResult[solution];
  }

  // update password with forgot-pass-token link
  validateForgotPasswordToken(token): Observable<any> {
    return this.httpClientService
      .getDataWithNoAuth(`${this.URLS.validateForgotPasswordToken}${token}`)
      .pipe(map((res) => res));
  }

  // get data with update-email-token link
  validateUpdateEmailToken(token): Observable<any> {
    return this.httpClientService
      .getDataWithNoAuth(`${this.URLS.validateUpdateEmailToken}${token}`)
      .pipe(map((res) => res));
  }

  // get data with update-email-token and email  
  validateUpdateEmailTokenWithEmail(token, payload): Observable<any> {
    return this.httpClientService
      .postWithNoAuth(`${this.URLS.validateUpdateEmailTokenWithEmail}${token}`, payload)
      .pipe(map((res) => res));
  }

  // update email and password for user
  updateEmailWithPassword(token, payload): Observable<any> {
    return this.httpClientService
      .postWithNoAuth(`${this.URLS.updateEmailWithPassword}${token}`, payload)
      .pipe(map((res) => res));
  }

  getEnvSpecificLink(val) {
    let url;
    if (environment.COOKIE_PREFIX === 'dev_') {
      url = 'https://dev-';
    } else if (environment.COOKIE_PREFIX === 'qa_') {
      url = 'https://qa-';
    } else {
      url = 'https://uat-';
    }

    return `${url}${val}`;
  }

  deactivateAccount(user) {
    return this.httpClientService.put(`${this.URLS.deactivate}/${user.userid}?hasPassword=true`,
      { is_active: false, password: user.password })
      .pipe(map((updatedUser) => updatedUser),
        catchError(this.handleError));
  }

  deactivateAccountWithEmail(payload) {
    return this.httpClientService.put(`${this.URLS.deactivatewithemail}`, payload)
      .pipe(map((updatedUser) => updatedUser),
        catchError(this.handleError));
  }
  
  updateEmail(payload) {
    return this.httpClientService.put(`${this.URLS.updateemail}`, payload)
      .pipe(map((updatedUser) => updatedUser),
        catchError(this.handleError));
  }
}
