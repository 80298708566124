import { hierarchyDropdownMockData } from '../fixtures';
import { Observable, of } from 'rxjs';

export class MockHierarchyDropdownServiceHandler {
  private mockData = hierarchyDropdownMockData;

  public getAccountHierarchy(): Observable<any> {
    return of(this.mockData.singleAccountDropdown.response200);
  }
}
