// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-btn-disabled {
  cursor: not-allowed !important;
}

.form-submit-container button {
  cursor: pointer;
}

.submission_message {
  font-size: 12px;
  padding: 10px 5px 0px 5px;
}

.grey-bg-loader {
  background: rgba(0, 0, 0, 0.3);
}

.site-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.site-loader-wrapper .loader {
  position: absolute;
  border-top: 8px solid color_new(blue, diversey_blue);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  animation: spin 2s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/app/update-email/update-email.component.scss"],"names":[],"mappings":"AACA;EACI,8BAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,eAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,8BAAA;AAAJ;;AAGA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AAAJ;AACI;EACE,kBAAA;EAEA,oDAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EAEA,kCAAA;AAAN","sourcesContent":["\n.submit-btn-disabled {\n    cursor: not-allowed !important;\n}\n\n.form-submit-container button {\n    cursor: pointer;\n}\n\n.submission_message {\n    font-size: 12px;\n    padding: 10px 5px 0px 5px;\n}\n\n.grey-bg-loader {\n    background: rgba(0,0,0,0.3);\n}\n  \n.site-loader-wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    z-index: 10;\n    .loader {\n      position: absolute;\n    //   border: 8px solid #f3f3f3;\n      border-top: 8px solid color_new(blue, diversey_blue);\n      border-radius: 50%;\n      width: 30px;\n      height: 30px;\n      top: 50%;\n      left: 50%;\n      margin-top: -15px;\n      margin-left: -15px;\n      -webkit-animation: spin 2s linear infinite;\n      animation: spin 2s linear infinite;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
