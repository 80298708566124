export class success {
  '8001' = {
    'en-US': 'Profile updated successfully!',
    'nl-NL': 'Profiel succesvol bijgewerkt!',
    'fr-FR': 'Votre profil a été mis à jour avec succès.',
    'de-DE': 'Profil erfolgreich aktualisiert!',
    'pl-PL': 'Profil zaktualizowany pomyślnie!',
    'zh-ZH': '资料更新成功！',
    'it-IT': 'Profilo caricato correttamente!',
    'tr-TR': 'Profil başarıyla güncellendi!',
    'fi-FI': 'Profiili on päivitetty onnistuneesti!',
    'da-DK': 'Profil opdateret med succes!',
    'ar-AR': 'تم تحديث الملف الشخصي بنجاح!',
    'pt-PT': 'Perfil atualizado com sucesso!',
    'es-ES': 'Perfil actualizado correctamente',
    'sv-SE': 'Profil uppdaterades.',
    'sv-FI': 'Profilen uppdaterades framgångsrikt!',
    'hu-HU': 'Sikeres profil feltöltés',
    'el-EL': 'Το προφίλ ενημερώθηκε με επιτυχία!',
    'no-NO': 'Profilen ble oppdatert!',
    'ru-RU': 'Профиль обновлен успешно!',
    'cs-CZ': 'Profil byl úspěšně aktualizován!',
    'sl-SL': 'Profil je bil uspešno posodobljen!',
    'sk-SK': 'Profil bol úspešne aktualizovaný!',
    'ko-KO': '프로필이 성공적으로 업데이트되었습니다!'
  };
  '8002' = {
    'en-US': 'You are registered successfully',
    'nl-NL': 'Je bent succesvol geregistreerd',
    'fr-FR': 'Vous êtes enregistré avec succès',
    'de-DE': 'Sie sind erfolgreich registriert',
    'pl-PL': 'Zostałeś pomyślnie zarejestrowany',
    'zh-ZH': '您已成功注册',
    'it-IT': 'Ti sei registrato correttamente.',
    'tr-TR': 'Başarılı bir şekilde kaydoldunuz',
    'fi-FI': 'Olet rekisteröitynyt onnistuneesti',
    'da-DK': 'Du er registreret med succes',
    'ar-AR': 'أنت مسجل بنجاح',
    'pt-PT': 'Você está registrado com sucesso',
    'es-ES': '¡Se ha registrado correctamente!',
    'sv-SE': 'Din registrering lyckades.',
    'sv-FI': 'Du är registrerad framgångsrikt',
    'hu-HU': 'Sikeres regisztráció',
    'el-EL': 'Έχετε εγγραφεί με επιτυχία',
    'no-NO': 'Du er registrert med hell',
    'ru-RU': 'Вы зарегистрированы успешно',
    'cs-CZ': 'Jste úspěšně zaregistrováni',
    'sl-SL': 'Prijavljeni ste uspešno',
    'sk-SK': 'Ste úspešne zaregistrovaný',
    'ko-KO': '당신은 성공적으로 등록되었습니다.'
  };
  '8003' = {
    'en-US': 'Your password has been reset successfully!',
    'nl-NL': 'Uw wachtwoord is succesvol gereset!',
    'fr-FR': 'Votre mot de passe a été réinitialisé avec succès!',
    'de-DE': 'Ihr Passwort wurde erfolgreich zurückgesetzt!',
    'pl-PL': 'Twoje hasło zostało zresetowane pomyślnie!',
    'zh-ZH': '您的密码已成功重置！',
    'it-IT': 'La tua password è stata resettata con successo!',
    'tr-TR': 'Parolanız başarıyla sıfırlandı!',
    'fi-FI': 'Salasanasi on nollattu onnistuneesti!',
    'da-DK': 'Dit kodeord er nulstillet!',
    'ar-AR': 'تم إعادة تعيين كلمة المرور الخاصة بك بنجاح!',
    'pt-PT': 'Sua senha foi redefinida com sucesso!',
    'es-ES': 'Su contraseña ha sido correctamente cambiada',
    'sv-SE': 'Din lösenord har återställts!',
    'sv-FI': 'Ditt lösenord har återställts framgångsrikt!',
    'hu-HU': 'Jelszavát sikeresen megváltoztatta',
    'el-EL': 'Ο κωδικός πρόσβασής σας επαναφέρεται με επιτυχία!',
    'no-NO': 'Ditt passord har blitt tilbakestilt vellykket!',
    'ru-RU': 'Ваш пароль успешно сбрасывается!',
    'cs-CZ': 'Vaše heslo bylo úspěšně obnoveno!',
    'sl-SL': 'Vaše geslo je bilo uspešno ponastavljeno!',
    'sk-SK': 'Vaše heslo bolo úspešne resetované!',
    'ko-KO': '비밀번호가 재설정되었습니다.'
  };
  '8004' = {
    'en-US': 'Terms And Conditions Accepted Successfully.',
    'nl-NL': 'Algemene voorwaarden zijn met succes geaccepteerd.',
    'fr-FR': 'Les conditions générales ont été acceptées avec succès.',
    'de-DE': 'Allgemeine Geschäftsbedingungen wurden erfolgreich akzeptiert.',
    'pl-PL': 'Warunki zaakceptowane z powodzeniem.',
    'zh-ZH': '条款和条件成功接受。',
    'it-IT': 'Termini e condizioni accettati con successo.',
    'tr-TR': 'Şartlar ve Koşullar Başarıyla Kabul Edildi.',
    'fi-FI': 'Ehdot ja ehdot hyväksytään onnistuneesti.',
    'da-DK': 'Vilkår og betingelser accepteres succesfuldt.',
    'ar-AR': 'الشروط والأحكام المقبولة بنجاح.',
    'pt-PT': 'Termos e Condições Aceitos com Sucesso.',
    'es-ES': 'Términos y condiciones aceptados satisfactoriamente',
    'sv-SE': 'Villkoren accepterade.',
    'sv-FI': 'Villkor och villkor accepteras framgångsrikt.',
    'hu-HU': 'Sikeresen elfogadott feltételek.',
    'el-EL': 'Όροι και Προϋποθέσεις που έγιναν δεκτοί με επιτυχία.',
    'no-NO': 'Vilkår og betingelser godtatt.',
    'ru-RU': 'Сроки и условия приняты успешно.',
    'cs-CZ': 'Smluvní podmínky byly úspěšně přijaty.',
    'sl-SL': 'Pogoji in pogoji, sprejeti uspešno.',
    'sk-SK': 'Podmienky prijaté úspešne.',
    'ko-KO': '이용 약관이 성공적으로 수락되었습니다.'
  };
  '8005' = {
    'en-US': 'Language changed Successfully.',
    'nl-NL': 'Taal is succesvol veranderd.',
    'fr-FR': 'La langue a été changée avec succès.',
    'de-DE': 'Die Sprache wurde erfolgreich geändert.',
    'pl-PL': 'Język zmienił się Pomyślnie.',
    'zh-ZH': '语言变化成功。',
    'it-IT': 'Lingua cambiata correttamente',
    'tr-TR': 'Dil başarıyla değişti.',
    'fi-FI': 'Kieli muuttui onnistuneesti.',
    'da-DK': 'Sprog ændret succesfuldt.',
    'ar-AR': 'تم تغيير اللغة بنجاح.',
    'pt-PT': 'Idioma alterado com sucesso.',
    'es-ES': 'Idioma cambiado satisfactoriamente',
    'sv-SE': 'Språk har ändrats.',
    'sv-FI': 'Språk ändrats framgångsrikt.',
    'hu-HU': 'A nyelv sikeresen megváltozott.',
    'el-EL': 'Η γλώσσα άλλαξε με επιτυχία.',
    'no-NO': 'Språk endret Vellykket.',
    'ru-RU': 'Язык успешно изменен.',
    'cs-CZ': 'Jazyk byl úspěšně změněn.',
    'sl-SL': 'Jezik se je uspešno spremenil.',
    'sk-SK': 'Jazyk úspešne zmenený.',
    'ko-KO': '언어가 성공적으로 변경되었습니다.'
  };
  '8006' = {
    'en-US': 'Account deactivated successfully. Logging you out...',
    'nl-NL': 'Account gedeactiveerd. Log je uit...',
    'fr-FR': 'Compte désactivé avec succès. Vous sortir...',
    'de-DE': 'Konto erfolgreich deaktiviert. Sie ausloggen...',
    'pl-PL': 'Konto zostało pomyślnie dezaktywowane. Wylogowywanie się...',
    'zh-ZH': '帐户已成功停用。正在注销您...',
    'it-IT': 'Account disattivato correttamente. Disconnessione...',
    'tr-TR': 'Hesap başarıyla devre dışı bırakıldı. Çıkış...',
    'fi-FI': 'Tilin aktivointi onnistui. Kirjaudun ulos...',
    'da-DK': 'Kontoen blev deaktiveret. Logger dig ud...',
    'pt-PT': 'Conta desativada com sucesso. Registrando você para fora...',
    'es-ES': 'Cuenta desactivada correctamente. Cerrar sesión...',
    'sv-SE': 'Kontot har inaktiverats. Logga ut dig...',
    'hu-HU': 'A fiók inaktiválása sikeresen megtörtént. Kijelentkeztetlek...',
    'el-EL': 'Ο λογαριασμός απενεργοποιήθηκε με επιτυχία. Αποσύνδεση...',
    'no-NO': 'Kontoen ble deaktivert. Logger deg ut ...',
    'ru-RU': 'Учетная запись успешно деактивирована. Регистрация вас ...',
    'cs-CZ': 'Účet byl úspěšně deaktivován. Odhlašování vás...',
    'sl-SL': 'Račun je bil uspešno deaktiviran. Odjavljanje...',
    'sk-SK': 'Účet bol úspešne deaktivovaný. Odhlásiť sa...',
    'ko-KO': '계정이 성공적으로 비활성화되었습니다. 로깅 하기...'
  };
}
