import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { UserService, LoaderService, CookieStorage, ToasterMessageService, LocalDataStoreService } from '../base/services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '../../assets/i18n/locale';
import { success } from '../../assets/i18n/success';
import { lables } from '../../assets/i18n/error';
import { encryptPassword, isDiverseyAdmin, isPowerAdmin } from '../base/utils';
import { CommonService } from 'app/shared/common.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../base/components/user-profile/user-profile.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  public forgotPassForm: UntypedFormGroup;
  public resetPassForm: UntypedFormGroup;
  public isSubmitted = false;
  loader = false;
  public country_locale = new locale();
  public success: success = new success();
  public error: lables = new lables();
  public locale;
  private userEmail: string;

  public userData = null;
  public passwordSuggestValue = '';

  constructor(private _fB: UntypedFormBuilder,
    private _userService: UserService,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private toasterMessageService: ToasterMessageService,
    private cookieStorage: CookieStorage,
    private commonService: CommonService,
    private localDataStoreService: LocalDataStoreService,
    private clipboard: Clipboard
  ) {
    const userLocale = this.cookieStorage.checkCookieAvailability('locale') ? this.cookieStorage.getCookie('locale') : 'en-US';
    if (userLocale && this.country_locale.dictionary[userLocale]) {
      this.locale = this.country_locale.dictionary[userLocale];
      this.translate.use(this.locale);
    }
  }

  ngOnInit() {
    this.loaderService.loaderState.subscribe((state) => {
      this.loader = state;
    });

    this.localDataStoreService.currentUserState.subscribe((res) => {
      if (res) {
        this.userData = res || null;
        this.userEmail = res['email'] || null;
      }
    });

    this.generateRandomPassword();

    this.resetPassForm = this._fB.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      newPassword: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/g)]],
      confirmPassword: ['', Validators.required]
    }, { validator: [this.passwordMatchValidator] });

    this.setPasswordMinLimit();
  }

  applyClass(isValid: boolean): string {
    if (isValid) {
      return 'login-btn login-btn-enable';
    }
    return 'login-btn login-btn-disabled';
  }

  setPasswordMinLimit() {
    if (this.resetPassForm && this.resetPassForm !== undefined && this.resetPassForm.controls['newPassword'] !== undefined) {
      if (this.checkDiverseyOrPowerAdmin()) {
        if (this.resetPassForm && this.resetPassForm.controls['newPassword']) {
          this.resetPassForm.controls['newPassword'].addValidators(Validators.minLength(15));
        }
      }
    }
  }

  generateRandomPassword() {
    const passwordtest = this.commonService.generateRandomPassword();
    this.passwordSuggestValue = passwordtest;
  }

  copyPasswordToClipboard() {
    this.clipboard.copy(this.passwordSuggestValue);
    this.toasterService.info('Password copied to the clipboard!');
  }

  onSubmitSetPassword(passData) {
    this.loaderService.show();
    delete passData['confirmPassword'];
    passData['currentPassword'] = encryptPassword(passData['currentPassword'], this.userEmail);
    passData['newPassword'] = encryptPassword(passData['newPassword'], this.userEmail);

    this._userService.resetPassword(passData).subscribe(res => {
      this.isSubmitted = true;
      const message = this.toasterMessageService.getSuccessMessage(8003);
      message ? this.toasterService.success(message) : null;

      this.loaderService.hide();
      this.notify.emit();
    }, (err) => {
      this.isSubmitted = false;
      this.loaderService.hide();
      const errCode = this.getErrorCode(err);
      if (errCode) {
        const message = this.toasterMessageService.getErrorMessage(errCode);
        message ? this.toasterService.error(message) : null;
      }
    });
  }

  getErrorCode(err) {
    if (err && err.error) {
      if (err.error.code) {
        const errorCode = err.error.code;
        return errorCode;
      }
    }
  }

  get newPassword() {
    return this.resetPassForm.get('newPassword') as UntypedFormControl;
  }

  get confirmPassword() {
    return this.resetPassForm.get('confirmPassword') as UntypedFormControl;
  }

  get currentPassword() {
    return this.resetPassForm.get('currentPassword') as UntypedFormControl;
  }

  passwordMatchValidator(AC: AbstractControl) {
    const pwd = AC.get('newPassword').value;
    const confirmPwd = AC.get('confirmPassword').value;
    if (pwd && confirmPwd) {
      if (pwd !== confirmPwd) {
        AC.get('confirmPassword').setErrors({ MatchPassword: true });
      } else {
        return null;
      }
    }
  }

  checkDiverseyOrPowerAdmin() {
    const { accessible_nodes, org_id, user_role_id } = this.userData;
    return (isPowerAdmin(this.userData.user_role_id) || isDiverseyAdmin(user_role_id, accessible_nodes, org_id));
  }


  /** same password allowed */
  // pwdNotChangedValidator(AC: AbstractControl) {
  //   const currPwd = AC.get('currentPassword').value;
  //   const pwd = AC.get('newPassword').value;
  //   if (currPwd && (currPwd === pwd)) {
  //     AC.get('newPassword').setErrors({ samePassword: true });
  //   } else {
  //     return null;
  //   }
  // }
}
