import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ResponsiveService {

  showResponsive = new Subject();

  updateResponsive(responsive) {
    this.showResponsive.next(responsive);
  }
}
