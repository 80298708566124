import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieStorage } from 'app/base/services/cookie-storage.service';

@Injectable()
export class LoggedInGuard  {

  constructor(
    private router: Router,
    private cookieStorage: CookieStorage) {
  }

  canActivate(): boolean {
    const token = this.cookieStorage.checkCookieAvailability('token');
    if (token) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}
