// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin: auto;
}

.spinner {
  width: 60px;
  text-align: center;
  display: inline-block;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #10069F;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/app-loader/app-loader.component.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;AAFJ;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,qBAAA;AADJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;EACA,wDAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ;AAcA;EACI;IAGI,mBAAA;EALN;EAOE;IACI,mBAAA;EALN;AACF","sourcesContent":["@import \"../../../../scss/config\";\n@import \"../../../../scss/colors\";\n\n:host {\n    margin: auto;\n}\n.spinner {\n    width: 60px;\n    text-align: center;\n    display: inline-block;\n}\n.spinner > div {\n    width: 10px;\n    height: 10px;\n    background-color: color_new(blue, diversey_blue);\n    border-radius: 100%;\n    display: inline-block;\n    animation: sk-bouncedelay 1.4s infinite ease-in-out both;\n}\n\n.spinner .bounce1 {\n    animation-delay: -0.32s;\n}\n\n.spinner .bounce2 {\n    animation-delay: -0.16s;\n}\n\n@-webkit-keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n        transform: scale(0);\n    }\n    40% {\n        transform: scale(1.0);\n    }\n}\n\n@keyframes sk-bouncedelay {\n    0%,\n    80%,\n    100% {\n        transform: scale(0);\n    }\n    40% {\n        transform: scale(1.0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
